.table {
  // wpx-add
  --#{$prefix}table-cell-font-size: #{$table-cell-font-size};
  --#{$prefix}table-th-bg: #{$table-th-bg};
  --#{$prefix}table-th-color: #{$table-th-color};
  --#{$prefix}table-th-font-size: #{$table-th-font-size};
  --#{$prefix}table-th-font-weight: #{$table-th-font-weight};
  --#{$prefix}table-th-letter-spacing: #{$table-th-letter-spacing};
  --#{$prefix}table-th-text-transform: #{$table-th-text-transform};
  // wpx-end

  margin-bottom: 0;

  thead th {
    font-size: var(--#{$prefix}table-th-font-size);
    font-weight: var(--#{$prefix}table-th-font-weight);
    text-transform: var(--#{$prefix}table-th-text-transform);
    letter-spacing: var(--#{$prefix}table-th-letter-spacing);
    border-bottom-width: $table-border-width;
    white-space: nowrap;
    vertical-align: $table-cell-vertical-align;
  }

  tbody {
    th {
      font-size: var(--#{$prefix}table-cell-font-size);
    }
  }

  td {
    font-size: var(--#{$prefix}table-cell-font-size);
    white-space: nowrap;

    .progress {
      height: 3px;
      width: 120px;
      margin: 0;
    }
  }
}

// Flushed table
.table-flush {
  th, td {
    border-left: 0;
    border-right: 0;
  }

  thead > tr {
    th {
      border-top: 0;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  tbody > tr {
    &:last-child {
      th, td {
        border-bottom: 0;
      }
    }
    th,
    td {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

// Spaced Rows table
// table with spaced rows and syles with the card's properties

.table-spaced {
  border-collapse: separate;
  border-spacing: 0 1rem;

  thead {
    th {
      border: 0;
    }
  }

  thead tr,
  tbody tr {
    th,
    td {
      position: relative;
      background-color: transparent;
      border-top: $table-border-width solid $table-border-color !important;
      border-bottom: $table-border-width solid $table-border-color !important;

      &:first-child {
        border-left: $table-border-width solid $table-border-color !important;
        @include border-radius($table-spaced-row-border-radius 0 0 $table-spaced-row-border-radius);

        &:after {
          border-left: $table-border-width solid $table-border-color;
          @include border-radius($table-spaced-row-border-radius 0 0 $table-spaced-row-border-radius);
        }
      }

      &:last-child {
        border-right: $table-border-width solid $table-border-color !important;
        @include border-radius(0 $table-spaced-row-border-radius $table-spaced-row-border-radius 0)
      }
    }
  }

  tbody tr {
    @include box-shadow($table-spaced-row-shadow);
    @include border-radius($table-spaced-row-border-radius);
  }
}