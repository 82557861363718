//
// Type
// Typography styles
//

// Surtitle
.surtitle {
  color: $surtitle-color;
  font-size: $surtitle-font-size;
  font-weight: $surtitle-font-weight;
  text-transform: $surtitle-text-transform;
}

// Muted links 
a.text-muted:hover {
  color: var(--#{$prefix}primary);
}

// Text highlight
@each $color, $value in $theme-colors {
  .text-highlight-#{$color} {
    background-image: linear-gradient(transparent 66%, rgba($value, .15) 34%);
    background-repeat: repeat-x;
  }
}


// Dropcaps  (add a dropcase on the first letter of the first paragraph)
.dropcaps {
  > p {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;

    &:first-child {
      &:first-letter {
        font-size: 3.5em;
        font-weight: 700;
        float: left;
        line-height: 1;
        margin-top: .05em;
        margin-right: .15em
      }
    }
  }
}