// Color system

// White/Grays/Black colors
$white:    #FFF !default;
$gray-50:  #f9fafb !default;
$gray-100: #f3f4f6 !default;
$gray-200: #e5e7eb !default;
$gray-300: #d1d5db !default;
$gray-400: #9ca3af !default;
$gray-500: #6b7280 !default;
$gray-600: #4b5563 !default;
$gray-700: #374151 !default; // Used for text color
$gray-800: #1f2937 !default; // Used for headings color
$gray-900: #111827 !default;
$black:    #000 !default;

// Base colors
$blue:    #0099FF !default;
$indigo:  #5C60F5 !default;
$purple:  #8957FF !default;
$pink:    #FF579A !default;
$magenta: #EE33FF !default;
$red:     #FF3366 !default;
$orange:  #FF8C00 !default;
$yellow:  #FFBB00 !default;
$lime:    #84cc16 !default;
$green:   #00CC88 !default;
$teal:    #22DDDD !default;
$cyan:    #00D4FF !default;

// Theme colors
$primary:       $gray-900 !default;
$secondary:     $lime !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $orange !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;