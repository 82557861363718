// Navbar

.navbar {
  --#{$prefix}navbar-bg: #{$navbar-nav-link-bg};
  --#{$prefix}navbar-color: #{$navbar-nav-link-color};
  --#{$prefix}navbar-icon-color: #{$navbar-icon-color};
  --#{$prefix}navbar-hover-bg: #{$navbar-nav-link-hover-bg};
  --#{$prefix}navbar-hover-color: #{$navbar-nav-link-hover-color};
  --#{$prefix}navbar-active-bg: #{$navbar-nav-link-active-bg};
  --#{$prefix}navbar-active-color: #{$navbar-nav-link-active-color};
  
  --#{$prefix}nav-link-padding-x: #{$navbar-nav-link-padding-x};
  --#{$prefix}nav-link-padding-y: #{$navbar-nav-link-padding-y};
  --#{$prefix}nav-link-border-radius: #{$navbar-nav-link-border-radius};

  position: relative;
  z-index: 100;

  // Container
  [class^="container"] {
    position: relative;
  }
}

.navbar-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.navbar-img-brand {
  font-size: initial;
}

// Top navbar

.navbar-top {
  position: relative;
  display: flex;
  align-items: center;

  .navbar-nav {
    flex-direction: row;
    .nav-item:last-child {
      margin-right: 0;
    }
    .nav-link {
      padding: .5rem .5rem;
    }
  }
}

// Collapse

.navbar-collapse {
  &:before {
    content: '';
    display: block;
    border-top-width: 1px;
    border-top-style: solid;
    margin: .75rem -1rem;
    opacity: 0;
    @include transition($transition-base);
  }

  &.show,
  &.collapsing {
    &:before {
      opacity: 1;
    }
  }
}

.navbar-light {
  .navbar-collapse::before {
    border-top-color: rgba($dark, .1);
  }

  .collapse .nav {
    &:before {
      border-color: rgba($dark, .1);
    }
  }
}

.navbar-dark {
  --#{$prefix}navbar-bg: #{$navbar-dark-nav-link-bg};
  --#{$prefix}navbar-color: #{$navbar-dark-nav-link-color};
  --#{$prefix}navbar-hover-bg: #{$navbar-dark-nav-link-hover-bg};
  --#{$prefix}navbar-hover-color: #{$navbar-dark-nav-link-hover-color};
  --#{$prefix}navbar-active-bg: #{$navbar-dark-nav-link-active-bg};
  --#{$prefix}navbar-active-color: #{$navbar-dark-nav-link-active-color};

  .navbar-collapse::before {
    border-top-color: rgba($white, .2);
  }

  .collapse .nav {
    &:before {
      border-color: rgba($white, .2);
    }
  }
}


// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.

.navbar-expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-up($next) {
        .navbar-nav {
          --#{$prefix}nav-link-padding-x: #{$navbar-nav-link-padding-x};
          --#{$prefix}nav-link-padding-y: #{$nav-link-padding-y};

          > .nav-item > .nav-link,
          > .nav-link {
            background-color: var(--#{$prefix}navbar-bg);
            color: var(--#{$prefix}navbar-color);
            padding-top: var(--#{$prefix}nav-link-padding-y);
            padding-bottom: var(--#{$prefix}nav-link-padding-y);
            @include transition($navbar-transition);
            @include border-radius(var(--#{$prefix}nav-link-border-radius));

            &:hover,
            &:focus {
              background-color: var(--#{$prefix}navbar-hover-bg);
              color: var(--#{$prefix}navbar-hover-color);
            }

            &.active {
              background-color: var(--#{$prefix}navbar-active-bg);
              color: var(--#{$prefix}navbar-active-color);
            }
          }
        }

        // Brand
        .navbar-brand {
          img {
            height: $navbar-brand-size;
          }

          &.dropdown-toggle {
            &:after {
              display: none;
            }
          }
        }

        // Navbar collapse
        .navbar-collapse {
          .collapse-header {
            display: none;
          }

          &:before {
            display: none;
          }
        }
      }
    }
  }
}


// Navbar toggler
// Button for toggling the navbar when in its collapsed state
.navbar-toggler {
    padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
    @include font-size($navbar-toggler-font-size);
    line-height: 1;
    background-color: transparent; // remove default button style
    border: $navbar-toggler-border-width solid transparent; // remove default button style
    @include border-radius($navbar-toggler-border-radius);
    @include transition($navbar-toggler-transition);

    &:hover {
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
        outline: 0;
        box-shadow: 0 0 0 $navbar-toggler-focus-width;
    }
}

.navbar-light {
    .navbar-toggler {
        color: $navbar-light-toggler-color;
        background-color: $navbar-light-toggler-bg;
        border-color: $navbar-light-toggler-border-color;

        &:focus {
            color: $navbar-light-toggler-focus-color;
            background-color: $navbar-light-toggler-focus-bg;
        }
    }
}

.navbar-dark {
    .navbar-toggler {
        color: $navbar-dark-toggler-color;
        background-color: $navbar-dark-toggler-bg;
        border-color: $navbar-dark-toggler-border-color;

        &:focus {
            color: $navbar-dark-toggler-focus-color;
            background-color: $navbar-dark-toggler-focus-bg;
        }
    }
}

.navbar-toggler-icon {
  width: $navbar-toggler-icon-width;
  height: $navbar-toggler-icon-height;
}
