:root, [data-bs-theme="light"] {
  --x-blue: #09f;
  --x-indigo: #5c60f5;
  --x-purple: #8957ff;
  --x-pink: #ff579a;
  --x-red: #f36;
  --x-orange: #ff8c00;
  --x-yellow: #fb0;
  --x-green: #0c8;
  --x-teal: #2dd;
  --x-cyan: #00d4ff;
  --x-black: #000;
  --x-white: #fff;
  --x-gray: #475569;
  --x-gray-dark: #1e293b;
  --x-gray-100: #f1f5f9;
  --x-gray-200: #e2e8f0;
  --x-gray-300: #cbd5e1;
  --x-gray-400: #94a3b8;
  --x-gray-500: #64748b;
  --x-gray-600: #475569;
  --x-gray-700: #334155;
  --x-gray-800: #1e293b;
  --x-gray-900: #0f172a;
  --x-primary: #8957ff;
  --x-secondary: #ff8c00;
  --x-success: #0c8;
  --x-info: #00d4ff;
  --x-warning: #ff8c00;
  --x-danger: #f36;
  --x-light: #f5f8fb;
  --x-dark: #0f172a;
  --x-primary-rgb: 137, 87, 255;
  --x-secondary-rgb: 255, 140, 0;
  --x-success-rgb: 0, 204, 136;
  --x-info-rgb: 0, 212, 255;
  --x-warning-rgb: 255, 140, 0;
  --x-danger-rgb: 255, 51, 102;
  --x-light-rgb: 245, 248, 251;
  --x-dark-rgb: 15, 23, 42;
  --x-primary-text-emphasis: #372366;
  --x-secondary-text-emphasis: #663800;
  --x-success-text-emphasis: #005236;
  --x-info-text-emphasis: #056;
  --x-warning-text-emphasis: #663800;
  --x-danger-text-emphasis: #661429;
  --x-light-text-emphasis: #334155;
  --x-dark-text-emphasis: #334155;
  --x-primary-bg-subtle: #e7ddff;
  --x-secondary-bg-subtle: #ffe8cc;
  --x-success-bg-subtle: #ccf5e7;
  --x-info-bg-subtle: #ccf6ff;
  --x-warning-bg-subtle: #ffe8cc;
  --x-danger-bg-subtle: #ffd6e0;
  --x-light-bg-subtle: #f8fafc;
  --x-dark-bg-subtle: #94a3b8;
  --x-primary-border-subtle: #d0bcff;
  --x-secondary-border-subtle: #ffd199;
  --x-success-border-subtle: #99ebcf;
  --x-info-border-subtle: #9ef;
  --x-warning-border-subtle: #ffd199;
  --x-danger-border-subtle: #ffadc2;
  --x-light-border-subtle: #e2e8f0;
  --x-dark-border-subtle: #64748b;
  --x-white-rgb: 255, 255, 255;
  --x-black-rgb: 0, 0, 0;
  --x-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --x-font-monospace: var(--x-font-monospace);
  --x-gradient: linear-gradient(180deg, rgba(255, 255, 255, .15), rgba(255, 255, 255, 0));
  --x-body-font-family: var(--x-font-sans-serif);
  --x-body-font-size: 1rem;
  --x-body-font-weight: 400;
  --x-body-line-height: 1.625;
  --x-body-color: #1e293b;
  --x-body-color-rgb: 30, 41, 59;
  --x-body-bg: #fff;
  --x-body-bg-rgb: 255, 255, 255;
  --x-emphasis-color: #0f172a;
  --x-emphasis-color-rgb: 15, 23, 42;
  --x-secondary-color: rgba(30, 41, 59, .75);
  --x-secondary-color-rgb: 30, 41, 59;
  --x-secondary-bg: #f1f5f9;
  --x-secondary-bg-rgb: 241, 245, 249;
  --x-tertiary-color: rgba(30, 41, 59, .5);
  --x-tertiary-color-rgb: 30, 41, 59;
  --x-tertiary-bg: #f8fafc;
  --x-tertiary-bg-rgb: 248, 250, 252;
  --x-heading-color: #0f172a;
  --x-link-color: #8957ff;
  --x-link-color-rgb: 137, 87, 255;
  --x-link-decoration: none;
  --x-link-hover-color: #744ad9;
  --x-link-hover-color-rgb: 116, 74, 217;
  --x-link-hover-decoration: none;
  --x-code-color: #ff579a;
  --x-highlight-color: #1e293b;
  --x-highlight-bg: #fcf8e3;
  --x-border-width: 1px;
  --x-border-style: solid;
  --x-border-color: #e2e8f0;
  --x-border-color-translucent: rgba(0, 0, 0, .176);
  --x-border-radius: .375rem;
  --x-border-radius-sm: .25rem;
  --x-border-radius-lg: .5rem;
  --x-border-radius-xl: .75rem;
  --x-border-radius-xxl: 2rem;
  --x-border-radius-2xl: var(--x-border-radius-xxl);
  --x-border-radius-pill: 50rem;
  --x-box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, .1), 0px 0px 1px 0px rgba(10, 22, 70, .06);
  --x-box-shadow-sm: 0px 1px 1px 0px rgba(10, 22, 70, .1), 0px 0px 1px 0px rgba(10, 22, 70, .06);
  --x-box-shadow-lg: 0px 6px 6px -1px rgba(10, 22, 70, .1), 0px 0px 1px 0px rgba(10, 22, 70, .06);
  --x-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, .075);
  --x-focus-ring-width: .25rem;
  --x-focus-ring-opacity: .25;
  --x-focus-ring-color: rgba(137, 87, 255, .25);
  --x-form-valid-color: #0c8;
  --x-form-valid-border-color: #0c8;
  --x-form-invalid-color: #f36;
  --x-form-invalid-border-color: #f36;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --x-body-color: #cbd5e1;
  --x-body-color-rgb: 203, 213, 225;
  --x-body-bg: #0f172a;
  --x-body-bg-rgb: 15, 23, 42;
  --x-emphasis-color: #fff;
  --x-emphasis-color-rgb: 255, 255, 255;
  --x-secondary-color: rgba(203, 213, 225, .75);
  --x-secondary-color-rgb: 203, 213, 225;
  --x-secondary-bg: #1e293b;
  --x-secondary-bg-rgb: 30, 41, 59;
  --x-tertiary-color: rgba(203, 213, 225, .5);
  --x-tertiary-color-rgb: 203, 213, 225;
  --x-tertiary-bg: #172033;
  --x-tertiary-bg-rgb: 23, 32, 51;
  --x-primary-text-emphasis: #b89aff;
  --x-secondary-text-emphasis: #ffba66;
  --x-success-text-emphasis: #66e0b8;
  --x-info-text-emphasis: #66e5ff;
  --x-warning-text-emphasis: #ffba66;
  --x-danger-text-emphasis: #ff85a3;
  --x-light-text-emphasis: #f1f5f9;
  --x-dark-text-emphasis: #cbd5e1;
  --x-primary-bg-subtle: #1b1133;
  --x-secondary-bg-subtle: #331c00;
  --x-success-bg-subtle: #00291b;
  --x-info-bg-subtle: #002a33;
  --x-warning-bg-subtle: #331c00;
  --x-danger-bg-subtle: #330a14;
  --x-light-bg-subtle: #1e293b;
  --x-dark-bg-subtle: #0f151e;
  --x-primary-border-subtle: #523499;
  --x-secondary-border-subtle: #995400;
  --x-success-border-subtle: #007a52;
  --x-info-border-subtle: #007f99;
  --x-warning-border-subtle: #995400;
  --x-danger-border-subtle: #991f3d;
  --x-light-border-subtle: #334155;
  --x-dark-border-subtle: #1e293b;
  --x-heading-color: inherit;
  --x-link-color: #b89aff;
  --x-link-hover-color: #c6aeff;
  --x-link-color-rgb: 184, 154, 255;
  --x-link-hover-color-rgb: 198, 174, 255;
  --x-code-color: #ff9ac2;
  --x-highlight-color: #cbd5e1;
  --x-highlight-bg: #664b00;
  --x-border-color: #334155;
  --x-border-color-translucent: rgba(255, 255, 255, .15);
  --x-form-valid-color: #66e0b8;
  --x-form-valid-border-color: #66e0b8;
  --x-form-invalid-color: #ff85a3;
  --x-form-invalid-border-color: #ff85a3;
}

:root, [data-bs-theme="light"] {
  --x-font-display: Satoshi, sans-serif, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --x-font-serif: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
}

:root .logo-light, [data-bs-theme="light"] .logo-light {
  display: none;
}

:root .logo-dark, [data-bs-theme="light"] .logo-dark, [data-bs-theme="dark"] .logo-light {
  display: block;
}

[data-bs-theme="dark"] .logo-dark {
  display: none;
}

[data-bs-theme="dark"] .btn-neutral {
  --x-btn-bg: var(--x-gray-800);
  --x-btn-border-color: var(--x-gray-800);
  --x-btn-color: var(--x-gray-100);
  --x-btn-hover-bg: var(--x-gray-700);
  --x-btn-hover-color: var(--x-white);
  --x-btn-hover-border-color: var(--x-gray-700);
}

iframe {
  vertical-align: middle;
  display: block;
}

figcaption, figure, main {
  margin: 0;
  display: block;
}

figure {
  max-width: 100%;
  min-height: 1px;
}

img, video {
  max-width: 100%;
  height: auto;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

a {
  outline: none;
}

.surtitle {
  color: #334155;
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 500;
}

a.text-muted:hover {
  color: var(--x-primary);
}

.text-highlight-primary {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(137, 87, 255, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-secondary {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(255, 140, 0, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-success {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(0, 204, 136, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-info {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(0, 212, 255, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-warning {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(255, 140, 0, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-danger {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(255, 51, 102, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-light {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(245, 248, 251, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-dark {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(15, 23, 42, .15) 34%);
  background-repeat: repeat-x;
}

.dropcaps > p {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.dropcaps > p:first-child:first-letter {
  float: left;
  margin-top: .05em;
  margin-right: .15em;
  font-size: 3.5em;
  font-weight: 700;
  line-height: 1;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--x-body-font-family);
  font-size: var(--x-body-font-size);
  font-weight: var(--x-body-font-weight);
  line-height: var(--x-body-line-height);
  color: var(--x-body-color);
  text-align: var(--x-body-text-align);
  background-color: var(--x-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--x-border-width) solid;
  opacity: .1;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: var(--x-heading-color);
  margin-top: 0;
  margin-bottom: 0;
  font-family: Satoshi, sans-serif;
  font-weight: 600;
  line-height: 1.3;
}

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.25rem;
  }
}

h2, .h2 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.75rem;
  }
}

h3, .h3 {
  font-size: calc(1.2625rem + .15vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.375rem;
  }
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: .875rem;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

abbr[title] {
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: 700;
}

small, .small {
  font-size: .875em;
}

mark, .mark {
  color: var(--x-highlight-color);
  background-color: var(--x-highlight-bg);
  padding: 0;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: rgba(var(--x-link-color-rgb), var(--x-link-opacity, 1));
  text-decoration: none;
}

a:hover {
  --x-link-color-rgb: var(--x-link-hover-color-rgb);
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--x-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--x-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--x-body-bg);
  background-color: var(--x-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 600;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--x-secondary-color);
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
  font-weight: 500;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  width: 100%;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.125rem;
  font-weight: 400;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-family: var(--x-font-display);
  font-weight: 700;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-family: var(--x-font-display);
  font-weight: 700;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-family: var(--x-font-display);
  font-weight: 700;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-family: var(--x-font-display);
  font-weight: 700;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-family: var(--x-font-display);
  font-weight: 700;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-family: var(--x-font-display);
  font-weight: 700;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #475569;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  background-color: var(--x-body-bg);
  border: var(--x-border-width) solid var(--x-border-color);
  border-radius: var(--x-border-radius);
  box-shadow: var(--x-box-shadow-sm);
  max-width: 100%;
  height: auto;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: var(--x-secondary-color);
  font-size: .875em;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --x-gutter-x: 1.5rem;
  --x-gutter-y: 0;
  padding-right: calc(var(--x-gutter-x) * .5);
  padding-left: calc(var(--x-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1360px;
  }
}

:root {
  --x-breakpoint-xs: 0;
  --x-breakpoint-sm: 576px;
  --x-breakpoint-md: 768px;
  --x-breakpoint-lg: 992px;
  --x-breakpoint-xl: 1200px;
  --x-breakpoint-xxl: 1400px;
}

.row {
  --x-gutter-x: 1.5rem;
  --x-gutter-y: 0;
  margin-top: calc(-1 * var(--x-gutter-y));
  margin-right: calc(-.5 * var(--x-gutter-x));
  margin-left: calc(-.5 * var(--x-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--x-gutter-x) * .5);
  padding-left: calc(var(--x-gutter-x) * .5);
  margin-top: var(--x-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.grid {
  grid-template-rows: repeat(var(--x-rows, 1), 1fr);
  grid-template-columns: repeat(var(--x-columns, 12), 1fr);
  gap: var(--x-gap, 1.5rem);
  display: grid;
}

.grid .g-col-1 {
  grid-column: auto / span 1;
}

.grid .g-col-2 {
  grid-column: auto / span 2;
}

.grid .g-col-3 {
  grid-column: auto / span 3;
}

.grid .g-col-4 {
  grid-column: auto / span 4;
}

.grid .g-col-5 {
  grid-column: auto / span 5;
}

.grid .g-col-6 {
  grid-column: auto / span 6;
}

.grid .g-col-7 {
  grid-column: auto / span 7;
}

.grid .g-col-8 {
  grid-column: auto / span 8;
}

.grid .g-col-9 {
  grid-column: auto / span 9;
}

.grid .g-col-10 {
  grid-column: auto / span 10;
}

.grid .g-col-11 {
  grid-column: auto / span 11;
}

.grid .g-col-12 {
  grid-column: auto / span 12;
}

.grid .g-start-1 {
  grid-column-start: 1;
}

.grid .g-start-2 {
  grid-column-start: 2;
}

.grid .g-start-3 {
  grid-column-start: 3;
}

.grid .g-start-4 {
  grid-column-start: 4;
}

.grid .g-start-5 {
  grid-column-start: 5;
}

.grid .g-start-6 {
  grid-column-start: 6;
}

.grid .g-start-7 {
  grid-column-start: 7;
}

.grid .g-start-8 {
  grid-column-start: 8;
}

.grid .g-start-9 {
  grid-column-start: 9;
}

.grid .g-start-10 {
  grid-column-start: 10;
}

.grid .g-start-11 {
  grid-column-start: 11;
}

@media (min-width: 576px) {
  .grid .g-col-sm-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-sm-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-sm-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-sm-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-sm-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-sm-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-sm-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-sm-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-sm-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-sm-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-sm-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-sm-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-sm-1 {
    grid-column-start: 1;
  }

  .grid .g-start-sm-2 {
    grid-column-start: 2;
  }

  .grid .g-start-sm-3 {
    grid-column-start: 3;
  }

  .grid .g-start-sm-4 {
    grid-column-start: 4;
  }

  .grid .g-start-sm-5 {
    grid-column-start: 5;
  }

  .grid .g-start-sm-6 {
    grid-column-start: 6;
  }

  .grid .g-start-sm-7 {
    grid-column-start: 7;
  }

  .grid .g-start-sm-8 {
    grid-column-start: 8;
  }

  .grid .g-start-sm-9 {
    grid-column-start: 9;
  }

  .grid .g-start-sm-10 {
    grid-column-start: 10;
  }

  .grid .g-start-sm-11 {
    grid-column-start: 11;
  }
}

@media (min-width: 768px) {
  .grid .g-col-md-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-md-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-md-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-md-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-md-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-md-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-md-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-md-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-md-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-md-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-md-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-md-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-md-1 {
    grid-column-start: 1;
  }

  .grid .g-start-md-2 {
    grid-column-start: 2;
  }

  .grid .g-start-md-3 {
    grid-column-start: 3;
  }

  .grid .g-start-md-4 {
    grid-column-start: 4;
  }

  .grid .g-start-md-5 {
    grid-column-start: 5;
  }

  .grid .g-start-md-6 {
    grid-column-start: 6;
  }

  .grid .g-start-md-7 {
    grid-column-start: 7;
  }

  .grid .g-start-md-8 {
    grid-column-start: 8;
  }

  .grid .g-start-md-9 {
    grid-column-start: 9;
  }

  .grid .g-start-md-10 {
    grid-column-start: 10;
  }

  .grid .g-start-md-11 {
    grid-column-start: 11;
  }
}

@media (min-width: 992px) {
  .grid .g-col-lg-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-lg-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-lg-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-lg-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-lg-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-lg-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-lg-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-lg-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-lg-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-lg-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-lg-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-lg-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-lg-1 {
    grid-column-start: 1;
  }

  .grid .g-start-lg-2 {
    grid-column-start: 2;
  }

  .grid .g-start-lg-3 {
    grid-column-start: 3;
  }

  .grid .g-start-lg-4 {
    grid-column-start: 4;
  }

  .grid .g-start-lg-5 {
    grid-column-start: 5;
  }

  .grid .g-start-lg-6 {
    grid-column-start: 6;
  }

  .grid .g-start-lg-7 {
    grid-column-start: 7;
  }

  .grid .g-start-lg-8 {
    grid-column-start: 8;
  }

  .grid .g-start-lg-9 {
    grid-column-start: 9;
  }

  .grid .g-start-lg-10 {
    grid-column-start: 10;
  }

  .grid .g-start-lg-11 {
    grid-column-start: 11;
  }
}

@media (min-width: 1200px) {
  .grid .g-col-xl-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-xl-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-xl-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-xl-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-xl-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-xl-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-xl-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-xl-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-xl-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-xl-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-xl-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-xl-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-xl-1 {
    grid-column-start: 1;
  }

  .grid .g-start-xl-2 {
    grid-column-start: 2;
  }

  .grid .g-start-xl-3 {
    grid-column-start: 3;
  }

  .grid .g-start-xl-4 {
    grid-column-start: 4;
  }

  .grid .g-start-xl-5 {
    grid-column-start: 5;
  }

  .grid .g-start-xl-6 {
    grid-column-start: 6;
  }

  .grid .g-start-xl-7 {
    grid-column-start: 7;
  }

  .grid .g-start-xl-8 {
    grid-column-start: 8;
  }

  .grid .g-start-xl-9 {
    grid-column-start: 9;
  }

  .grid .g-start-xl-10 {
    grid-column-start: 10;
  }

  .grid .g-start-xl-11 {
    grid-column-start: 11;
  }
}

@media (min-width: 1400px) {
  .grid .g-col-xxl-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-xxl-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-xxl-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-xxl-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-xxl-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-xxl-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-xxl-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-xxl-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-xxl-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-xxl-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-xxl-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-xxl-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-xxl-1 {
    grid-column-start: 1;
  }

  .grid .g-start-xxl-2 {
    grid-column-start: 2;
  }

  .grid .g-start-xxl-3 {
    grid-column-start: 3;
  }

  .grid .g-start-xxl-4 {
    grid-column-start: 4;
  }

  .grid .g-start-xxl-5 {
    grid-column-start: 5;
  }

  .grid .g-start-xxl-6 {
    grid-column-start: 6;
  }

  .grid .g-start-xxl-7 {
    grid-column-start: 7;
  }

  .grid .g-start-xxl-8 {
    grid-column-start: 8;
  }

  .grid .g-start-xxl-9 {
    grid-column-start: 9;
  }

  .grid .g-start-xxl-10 {
    grid-column-start: 10;
  }

  .grid .g-start-xxl-11 {
    grid-column-start: 11;
  }
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  flex: none;
  width: auto;
}

.row-cols-1 > * {
  flex: none;
  width: 100%;
}

.row-cols-2 > * {
  flex: none;
  width: 50%;
}

.row-cols-3 > * {
  flex: none;
  width: 33.3333%;
}

.row-cols-4 > * {
  flex: none;
  width: 25%;
}

.row-cols-5 > * {
  flex: none;
  width: 20%;
}

.row-cols-6 > * {
  flex: none;
  width: 16.6667%;
}

.col-auto {
  flex: none;
  width: auto;
}

.col-1 {
  flex: none;
  width: 8.33333%;
}

.col-2 {
  flex: none;
  width: 16.6667%;
}

.col-3 {
  flex: none;
  width: 25%;
}

.col-4 {
  flex: none;
  width: 33.3333%;
}

.col-5 {
  flex: none;
  width: 41.6667%;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-7 {
  flex: none;
  width: 58.3333%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-9 {
  flex: none;
  width: 75%;
}

.col-10 {
  flex: none;
  width: 83.3333%;
}

.col-11 {
  flex: none;
  width: 91.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --x-gutter-x: 0;
}

.g-0, .gy-0 {
  --x-gutter-y: 0;
}

.g-1, .gx-1 {
  --x-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --x-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --x-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --x-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --x-gutter-x: .75rem;
}

.g-3, .gy-3 {
  --x-gutter-y: .75rem;
}

.g-4, .gx-4 {
  --x-gutter-x: 1rem;
}

.g-4, .gy-4 {
  --x-gutter-y: 1rem;
}

.g-5, .gx-5 {
  --x-gutter-x: 1.25rem;
}

.g-5, .gy-5 {
  --x-gutter-y: 1.25rem;
}

.g-6, .gx-6 {
  --x-gutter-x: 1.5rem;
}

.g-6, .gy-6 {
  --x-gutter-y: 1.5rem;
}

.g-7, .gx-7 {
  --x-gutter-x: 1.75rem;
}

.g-7, .gy-7 {
  --x-gutter-y: 1.75rem;
}

.g-8, .gx-8 {
  --x-gutter-x: 2rem;
}

.g-8, .gy-8 {
  --x-gutter-y: 2rem;
}

.g-10, .gx-10 {
  --x-gutter-x: 2.5rem;
}

.g-10, .gy-10 {
  --x-gutter-y: 2.5rem;
}

.g-12, .gx-12 {
  --x-gutter-x: 3rem;
}

.g-12, .gy-12 {
  --x-gutter-y: 3rem;
}

.g-14, .gx-14 {
  --x-gutter-x: 3.5rem;
}

.g-14, .gy-14 {
  --x-gutter-y: 3.5rem;
}

.g-16, .gx-16 {
  --x-gutter-x: 4rem;
}

.g-16, .gy-16 {
  --x-gutter-y: 4rem;
}

.g-18, .gx-18 {
  --x-gutter-x: 4.5rem;
}

.g-18, .gy-18 {
  --x-gutter-y: 4.5rem;
}

.g-20, .gx-20 {
  --x-gutter-x: 5rem;
}

.g-20, .gy-20 {
  --x-gutter-y: 5rem;
}

.g-24, .gx-24 {
  --x-gutter-x: 6rem;
}

.g-24, .gy-24 {
  --x-gutter-y: 6rem;
}

.g-32, .gx-32 {
  --x-gutter-x: 8rem;
}

.g-32, .gy-32 {
  --x-gutter-y: 8rem;
}

.g-40, .gx-40 {
  --x-gutter-x: 10rem;
}

.g-40, .gy-40 {
  --x-gutter-y: 10rem;
}

.g-48, .gx-48 {
  --x-gutter-x: 12rem;
}

.g-48, .gy-48 {
  --x-gutter-y: 12rem;
}

.g-56, .gx-56 {
  --x-gutter-x: 14rem;
}

.g-56, .gy-56 {
  --x-gutter-y: 14rem;
}

.g-64, .gx-64 {
  --x-gutter-x: 16rem;
}

.g-64, .gy-64 {
  --x-gutter-y: 16rem;
}

.g-72, .gx-72 {
  --x-gutter-x: 18rem;
}

.g-72, .gy-72 {
  --x-gutter-y: 18rem;
}

.g-80, .gx-80 {
  --x-gutter-x: 20rem;
}

.g-80, .gy-80 {
  --x-gutter-y: 20rem;
}

.g-px, .gx-px {
  --x-gutter-x: 1px;
}

.g-px, .gy-px {
  --x-gutter-y: 1px;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-sm-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
  }

  .col-sm-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-3 {
    flex: none;
    width: 25%;
  }

  .col-sm-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-sm-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-sm-6 {
    flex: none;
    width: 50%;
  }

  .col-sm-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-sm-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-sm-9 {
    flex: none;
    width: 75%;
  }

  .col-sm-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-sm-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-sm-12 {
    flex: none;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --x-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --x-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --x-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --x-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --x-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --x-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --x-gutter-x: .75rem;
  }

  .g-sm-3, .gy-sm-3 {
    --x-gutter-y: .75rem;
  }

  .g-sm-4, .gx-sm-4 {
    --x-gutter-x: 1rem;
  }

  .g-sm-4, .gy-sm-4 {
    --x-gutter-y: 1rem;
  }

  .g-sm-5, .gx-sm-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-sm-5, .gy-sm-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-sm-6, .gx-sm-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-sm-6, .gy-sm-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-sm-7, .gx-sm-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-sm-7, .gy-sm-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-sm-8, .gx-sm-8 {
    --x-gutter-x: 2rem;
  }

  .g-sm-8, .gy-sm-8 {
    --x-gutter-y: 2rem;
  }

  .g-sm-10, .gx-sm-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-sm-10, .gy-sm-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-sm-12, .gx-sm-12 {
    --x-gutter-x: 3rem;
  }

  .g-sm-12, .gy-sm-12 {
    --x-gutter-y: 3rem;
  }

  .g-sm-14, .gx-sm-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-sm-14, .gy-sm-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-sm-16, .gx-sm-16 {
    --x-gutter-x: 4rem;
  }

  .g-sm-16, .gy-sm-16 {
    --x-gutter-y: 4rem;
  }

  .g-sm-18, .gx-sm-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-sm-18, .gy-sm-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-sm-20, .gx-sm-20 {
    --x-gutter-x: 5rem;
  }

  .g-sm-20, .gy-sm-20 {
    --x-gutter-y: 5rem;
  }

  .g-sm-24, .gx-sm-24 {
    --x-gutter-x: 6rem;
  }

  .g-sm-24, .gy-sm-24 {
    --x-gutter-y: 6rem;
  }

  .g-sm-32, .gx-sm-32 {
    --x-gutter-x: 8rem;
  }

  .g-sm-32, .gy-sm-32 {
    --x-gutter-y: 8rem;
  }

  .g-sm-40, .gx-sm-40 {
    --x-gutter-x: 10rem;
  }

  .g-sm-40, .gy-sm-40 {
    --x-gutter-y: 10rem;
  }

  .g-sm-48, .gx-sm-48 {
    --x-gutter-x: 12rem;
  }

  .g-sm-48, .gy-sm-48 {
    --x-gutter-y: 12rem;
  }

  .g-sm-56, .gx-sm-56 {
    --x-gutter-x: 14rem;
  }

  .g-sm-56, .gy-sm-56 {
    --x-gutter-y: 14rem;
  }

  .g-sm-64, .gx-sm-64 {
    --x-gutter-x: 16rem;
  }

  .g-sm-64, .gy-sm-64 {
    --x-gutter-y: 16rem;
  }

  .g-sm-72, .gx-sm-72 {
    --x-gutter-x: 18rem;
  }

  .g-sm-72, .gy-sm-72 {
    --x-gutter-y: 18rem;
  }

  .g-sm-80, .gx-sm-80 {
    --x-gutter-x: 20rem;
  }

  .g-sm-80, .gy-sm-80 {
    --x-gutter-y: 20rem;
  }

  .g-sm-px, .gx-sm-px {
    --x-gutter-x: 1px;
  }

  .g-sm-px, .gy-sm-px {
    --x-gutter-y: 1px;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-md-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
  }

  .col-md-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }

  .col-md-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-md-9 {
    flex: none;
    width: 75%;
  }

  .col-md-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-md-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-md-12 {
    flex: none;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --x-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --x-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --x-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --x-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --x-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --x-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --x-gutter-x: .75rem;
  }

  .g-md-3, .gy-md-3 {
    --x-gutter-y: .75rem;
  }

  .g-md-4, .gx-md-4 {
    --x-gutter-x: 1rem;
  }

  .g-md-4, .gy-md-4 {
    --x-gutter-y: 1rem;
  }

  .g-md-5, .gx-md-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-md-5, .gy-md-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-md-6, .gx-md-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-md-6, .gy-md-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-md-7, .gx-md-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-md-7, .gy-md-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-md-8, .gx-md-8 {
    --x-gutter-x: 2rem;
  }

  .g-md-8, .gy-md-8 {
    --x-gutter-y: 2rem;
  }

  .g-md-10, .gx-md-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-md-10, .gy-md-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-md-12, .gx-md-12 {
    --x-gutter-x: 3rem;
  }

  .g-md-12, .gy-md-12 {
    --x-gutter-y: 3rem;
  }

  .g-md-14, .gx-md-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-md-14, .gy-md-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-md-16, .gx-md-16 {
    --x-gutter-x: 4rem;
  }

  .g-md-16, .gy-md-16 {
    --x-gutter-y: 4rem;
  }

  .g-md-18, .gx-md-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-md-18, .gy-md-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-md-20, .gx-md-20 {
    --x-gutter-x: 5rem;
  }

  .g-md-20, .gy-md-20 {
    --x-gutter-y: 5rem;
  }

  .g-md-24, .gx-md-24 {
    --x-gutter-x: 6rem;
  }

  .g-md-24, .gy-md-24 {
    --x-gutter-y: 6rem;
  }

  .g-md-32, .gx-md-32 {
    --x-gutter-x: 8rem;
  }

  .g-md-32, .gy-md-32 {
    --x-gutter-y: 8rem;
  }

  .g-md-40, .gx-md-40 {
    --x-gutter-x: 10rem;
  }

  .g-md-40, .gy-md-40 {
    --x-gutter-y: 10rem;
  }

  .g-md-48, .gx-md-48 {
    --x-gutter-x: 12rem;
  }

  .g-md-48, .gy-md-48 {
    --x-gutter-y: 12rem;
  }

  .g-md-56, .gx-md-56 {
    --x-gutter-x: 14rem;
  }

  .g-md-56, .gy-md-56 {
    --x-gutter-y: 14rem;
  }

  .g-md-64, .gx-md-64 {
    --x-gutter-x: 16rem;
  }

  .g-md-64, .gy-md-64 {
    --x-gutter-y: 16rem;
  }

  .g-md-72, .gx-md-72 {
    --x-gutter-x: 18rem;
  }

  .g-md-72, .gy-md-72 {
    --x-gutter-y: 18rem;
  }

  .g-md-80, .gx-md-80 {
    --x-gutter-x: 20rem;
  }

  .g-md-80, .gy-md-80 {
    --x-gutter-y: 20rem;
  }

  .g-md-px, .gx-md-px {
    --x-gutter-x: 1px;
  }

  .g-md-px, .gy-md-px {
    --x-gutter-y: 1px;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-lg-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
  }

  .col-lg-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-lg-9 {
    flex: none;
    width: 75%;
  }

  .col-lg-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-lg-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-lg-12 {
    flex: none;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --x-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --x-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --x-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --x-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --x-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --x-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --x-gutter-x: .75rem;
  }

  .g-lg-3, .gy-lg-3 {
    --x-gutter-y: .75rem;
  }

  .g-lg-4, .gx-lg-4 {
    --x-gutter-x: 1rem;
  }

  .g-lg-4, .gy-lg-4 {
    --x-gutter-y: 1rem;
  }

  .g-lg-5, .gx-lg-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-lg-5, .gy-lg-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-lg-6, .gx-lg-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-lg-6, .gy-lg-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-lg-7, .gx-lg-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-lg-7, .gy-lg-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-lg-8, .gx-lg-8 {
    --x-gutter-x: 2rem;
  }

  .g-lg-8, .gy-lg-8 {
    --x-gutter-y: 2rem;
  }

  .g-lg-10, .gx-lg-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-lg-10, .gy-lg-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-lg-12, .gx-lg-12 {
    --x-gutter-x: 3rem;
  }

  .g-lg-12, .gy-lg-12 {
    --x-gutter-y: 3rem;
  }

  .g-lg-14, .gx-lg-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-lg-14, .gy-lg-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-lg-16, .gx-lg-16 {
    --x-gutter-x: 4rem;
  }

  .g-lg-16, .gy-lg-16 {
    --x-gutter-y: 4rem;
  }

  .g-lg-18, .gx-lg-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-lg-18, .gy-lg-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-lg-20, .gx-lg-20 {
    --x-gutter-x: 5rem;
  }

  .g-lg-20, .gy-lg-20 {
    --x-gutter-y: 5rem;
  }

  .g-lg-24, .gx-lg-24 {
    --x-gutter-x: 6rem;
  }

  .g-lg-24, .gy-lg-24 {
    --x-gutter-y: 6rem;
  }

  .g-lg-32, .gx-lg-32 {
    --x-gutter-x: 8rem;
  }

  .g-lg-32, .gy-lg-32 {
    --x-gutter-y: 8rem;
  }

  .g-lg-40, .gx-lg-40 {
    --x-gutter-x: 10rem;
  }

  .g-lg-40, .gy-lg-40 {
    --x-gutter-y: 10rem;
  }

  .g-lg-48, .gx-lg-48 {
    --x-gutter-x: 12rem;
  }

  .g-lg-48, .gy-lg-48 {
    --x-gutter-y: 12rem;
  }

  .g-lg-56, .gx-lg-56 {
    --x-gutter-x: 14rem;
  }

  .g-lg-56, .gy-lg-56 {
    --x-gutter-y: 14rem;
  }

  .g-lg-64, .gx-lg-64 {
    --x-gutter-x: 16rem;
  }

  .g-lg-64, .gy-lg-64 {
    --x-gutter-y: 16rem;
  }

  .g-lg-72, .gx-lg-72 {
    --x-gutter-x: 18rem;
  }

  .g-lg-72, .gy-lg-72 {
    --x-gutter-y: 18rem;
  }

  .g-lg-80, .gx-lg-80 {
    --x-gutter-x: 20rem;
  }

  .g-lg-80, .gy-lg-80 {
    --x-gutter-y: 20rem;
  }

  .g-lg-px, .gx-lg-px {
    --x-gutter-x: 1px;
  }

  .g-lg-px, .gy-lg-px {
    --x-gutter-y: 1px;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
  }

  .col-xl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xl-6 {
    flex: none;
    width: 50%;
  }

  .col-xl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xl-9 {
    flex: none;
    width: 75%;
  }

  .col-xl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --x-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --x-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --x-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --x-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --x-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --x-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --x-gutter-x: .75rem;
  }

  .g-xl-3, .gy-xl-3 {
    --x-gutter-y: .75rem;
  }

  .g-xl-4, .gx-xl-4 {
    --x-gutter-x: 1rem;
  }

  .g-xl-4, .gy-xl-4 {
    --x-gutter-y: 1rem;
  }

  .g-xl-5, .gx-xl-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-xl-5, .gy-xl-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-xl-6, .gx-xl-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-xl-6, .gy-xl-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-xl-7, .gx-xl-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-xl-7, .gy-xl-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-xl-8, .gx-xl-8 {
    --x-gutter-x: 2rem;
  }

  .g-xl-8, .gy-xl-8 {
    --x-gutter-y: 2rem;
  }

  .g-xl-10, .gx-xl-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-xl-10, .gy-xl-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-xl-12, .gx-xl-12 {
    --x-gutter-x: 3rem;
  }

  .g-xl-12, .gy-xl-12 {
    --x-gutter-y: 3rem;
  }

  .g-xl-14, .gx-xl-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-xl-14, .gy-xl-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-xl-16, .gx-xl-16 {
    --x-gutter-x: 4rem;
  }

  .g-xl-16, .gy-xl-16 {
    --x-gutter-y: 4rem;
  }

  .g-xl-18, .gx-xl-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-xl-18, .gy-xl-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-xl-20, .gx-xl-20 {
    --x-gutter-x: 5rem;
  }

  .g-xl-20, .gy-xl-20 {
    --x-gutter-y: 5rem;
  }

  .g-xl-24, .gx-xl-24 {
    --x-gutter-x: 6rem;
  }

  .g-xl-24, .gy-xl-24 {
    --x-gutter-y: 6rem;
  }

  .g-xl-32, .gx-xl-32 {
    --x-gutter-x: 8rem;
  }

  .g-xl-32, .gy-xl-32 {
    --x-gutter-y: 8rem;
  }

  .g-xl-40, .gx-xl-40 {
    --x-gutter-x: 10rem;
  }

  .g-xl-40, .gy-xl-40 {
    --x-gutter-y: 10rem;
  }

  .g-xl-48, .gx-xl-48 {
    --x-gutter-x: 12rem;
  }

  .g-xl-48, .gy-xl-48 {
    --x-gutter-y: 12rem;
  }

  .g-xl-56, .gx-xl-56 {
    --x-gutter-x: 14rem;
  }

  .g-xl-56, .gy-xl-56 {
    --x-gutter-y: 14rem;
  }

  .g-xl-64, .gx-xl-64 {
    --x-gutter-x: 16rem;
  }

  .g-xl-64, .gy-xl-64 {
    --x-gutter-y: 16rem;
  }

  .g-xl-72, .gx-xl-72 {
    --x-gutter-x: 18rem;
  }

  .g-xl-72, .gy-xl-72 {
    --x-gutter-y: 18rem;
  }

  .g-xl-80, .gx-xl-80 {
    --x-gutter-x: 20rem;
  }

  .g-xl-80, .gy-xl-80 {
    --x-gutter-y: 20rem;
  }

  .g-xl-px, .gx-xl-px {
    --x-gutter-x: 1px;
  }

  .g-xl-px, .gy-xl-px {
    --x-gutter-y: 1px;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xxl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-auto {
    flex: none;
    width: auto;
  }

  .col-xxl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-3 {
    flex: none;
    width: 25%;
  }

  .col-xxl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xxl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xxl-6 {
    flex: none;
    width: 50%;
  }

  .col-xxl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xxl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xxl-9 {
    flex: none;
    width: 75%;
  }

  .col-xxl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xxl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xxl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --x-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --x-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --x-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --x-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --x-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --x-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --x-gutter-x: .75rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --x-gutter-y: .75rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --x-gutter-x: 1rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --x-gutter-y: 1rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-xxl-6, .gx-xxl-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-xxl-6, .gy-xxl-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-xxl-7, .gx-xxl-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-xxl-7, .gy-xxl-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-xxl-8, .gx-xxl-8 {
    --x-gutter-x: 2rem;
  }

  .g-xxl-8, .gy-xxl-8 {
    --x-gutter-y: 2rem;
  }

  .g-xxl-10, .gx-xxl-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-xxl-10, .gy-xxl-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-xxl-12, .gx-xxl-12 {
    --x-gutter-x: 3rem;
  }

  .g-xxl-12, .gy-xxl-12 {
    --x-gutter-y: 3rem;
  }

  .g-xxl-14, .gx-xxl-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-xxl-14, .gy-xxl-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-xxl-16, .gx-xxl-16 {
    --x-gutter-x: 4rem;
  }

  .g-xxl-16, .gy-xxl-16 {
    --x-gutter-y: 4rem;
  }

  .g-xxl-18, .gx-xxl-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-xxl-18, .gy-xxl-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-xxl-20, .gx-xxl-20 {
    --x-gutter-x: 5rem;
  }

  .g-xxl-20, .gy-xxl-20 {
    --x-gutter-y: 5rem;
  }

  .g-xxl-24, .gx-xxl-24 {
    --x-gutter-x: 6rem;
  }

  .g-xxl-24, .gy-xxl-24 {
    --x-gutter-y: 6rem;
  }

  .g-xxl-32, .gx-xxl-32 {
    --x-gutter-x: 8rem;
  }

  .g-xxl-32, .gy-xxl-32 {
    --x-gutter-y: 8rem;
  }

  .g-xxl-40, .gx-xxl-40 {
    --x-gutter-x: 10rem;
  }

  .g-xxl-40, .gy-xxl-40 {
    --x-gutter-y: 10rem;
  }

  .g-xxl-48, .gx-xxl-48 {
    --x-gutter-x: 12rem;
  }

  .g-xxl-48, .gy-xxl-48 {
    --x-gutter-y: 12rem;
  }

  .g-xxl-56, .gx-xxl-56 {
    --x-gutter-x: 14rem;
  }

  .g-xxl-56, .gy-xxl-56 {
    --x-gutter-y: 14rem;
  }

  .g-xxl-64, .gx-xxl-64 {
    --x-gutter-x: 16rem;
  }

  .g-xxl-64, .gy-xxl-64 {
    --x-gutter-y: 16rem;
  }

  .g-xxl-72, .gx-xxl-72 {
    --x-gutter-x: 18rem;
  }

  .g-xxl-72, .gy-xxl-72 {
    --x-gutter-y: 18rem;
  }

  .g-xxl-80, .gx-xxl-80 {
    --x-gutter-x: 20rem;
  }

  .g-xxl-80, .gy-xxl-80 {
    --x-gutter-y: 20rem;
  }

  .g-xxl-px, .gx-xxl-px {
    --x-gutter-x: 1px;
  }

  .g-xxl-px, .gy-xxl-px {
    --x-gutter-y: 1px;
  }
}

.table {
  --x-table-color-type: initial;
  --x-table-bg-type: initial;
  --x-table-color-state: initial;
  --x-table-bg-state: initial;
  --x-table-color: var(--x-emphasis-color);
  --x-table-bg: var(--x-body-bg);
  --x-table-border-color: var(--x-border-color);
  --x-table-accent-bg: transparent;
  --x-table-striped-color: var(--x-emphasis-color);
  --x-table-striped-bg: rgba(var(--x-emphasis-color-rgb), .05);
  --x-table-active-color: var(--x-emphasis-color);
  --x-table-active-bg: rgba(var(--x-emphasis-color-rgb), .1);
  --x-table-hover-color: var(--x-emphasis-color);
  --x-table-hover-bg: rgba(var(--x-emphasis-color-rgb), .075);
  vertical-align: middle;
  border-color: var(--x-table-border-color);
  width: 100%;
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  color: var(--x-table-color-state, var(--x-table-color-type, var(--x-table-color)));
  background-color: var(--x-table-bg);
  border-bottom-width: var(--x-border-width);
  box-shadow: inset 0 0 0 9999px var(--x-table-bg-state, var(--x-table-bg-type, var(--x-table-accent-bg)));
  padding: 1rem 1.5rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--x-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .375rem 1.5rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--x-border-width) 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--x-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --x-table-color-type: var(--x-table-striped-color);
  --x-table-bg-type: var(--x-table-striped-bg);
}

.table-active {
  --x-table-color-state: var(--x-table-active-color);
  --x-table-bg-state: var(--x-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --x-table-color-state: var(--x-table-hover-color);
  --x-table-bg-state: var(--x-table-hover-bg);
}

.table-primary {
  --x-table-color: #000;
  --x-table-bg: #e7ddff;
  --x-table-border-color: #b9b1cc;
  --x-table-striped-bg: #dbd2f2;
  --x-table-striped-color: #000;
  --x-table-active-bg: #d0c7e6;
  --x-table-active-color: #000;
  --x-table-hover-bg: #d6ccec;
  --x-table-hover-color: #000;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-secondary {
  --x-table-color: #000;
  --x-table-bg: #ffe8cc;
  --x-table-border-color: #ccbaa3;
  --x-table-striped-bg: #f2dcc2;
  --x-table-striped-color: #000;
  --x-table-active-bg: #e6d1b8;
  --x-table-active-color: #000;
  --x-table-hover-bg: #ecd7bd;
  --x-table-hover-color: #000;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-success {
  --x-table-color: #000;
  --x-table-bg: #ccf5e7;
  --x-table-border-color: #a3c4b9;
  --x-table-striped-bg: #c2e9db;
  --x-table-striped-color: #000;
  --x-table-active-bg: #b8ddd0;
  --x-table-active-color: #000;
  --x-table-hover-bg: #bde3d6;
  --x-table-hover-color: #000;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-info {
  --x-table-color: #000;
  --x-table-bg: #ccf6ff;
  --x-table-border-color: #a3c5cc;
  --x-table-striped-bg: #c2eaf2;
  --x-table-striped-color: #000;
  --x-table-active-bg: #b8dde6;
  --x-table-active-color: #000;
  --x-table-hover-bg: #bde4ec;
  --x-table-hover-color: #000;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-warning {
  --x-table-color: #000;
  --x-table-bg: #ffe8cc;
  --x-table-border-color: #ccbaa3;
  --x-table-striped-bg: #f2dcc2;
  --x-table-striped-color: #000;
  --x-table-active-bg: #e6d1b8;
  --x-table-active-color: #000;
  --x-table-hover-bg: #ecd7bd;
  --x-table-hover-color: #000;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-danger {
  --x-table-color: #000;
  --x-table-bg: #ffd6e0;
  --x-table-border-color: #ccabb3;
  --x-table-striped-bg: #f2cbd5;
  --x-table-striped-color: #000;
  --x-table-active-bg: #e6c1ca;
  --x-table-active-color: #000;
  --x-table-hover-bg: #ecc6cf;
  --x-table-hover-color: #000;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-light {
  --x-table-color: #000;
  --x-table-bg: #f5f8fb;
  --x-table-border-color: #c4c6c9;
  --x-table-striped-bg: #e9ecee;
  --x-table-striped-color: #000;
  --x-table-active-bg: #dddfe2;
  --x-table-active-color: #000;
  --x-table-hover-bg: #e3e5e8;
  --x-table-hover-color: #000;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-dark {
  --x-table-color: #fff;
  --x-table-bg: #0f172a;
  --x-table-border-color: #3f4555;
  --x-table-striped-bg: #1b2335;
  --x-table-striped-color: #fff;
  --x-table-active-bg: #272e3f;
  --x-table-active-color: #fff;
  --x-table-hover-bg: #21283a;
  --x-table-hover-color: #fff;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::-webkit-file-upload-button {
  border-right-width: var(--x-border-width);
  margin-right: 1rem;
}

.form-control:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::file-selector-button {
  border-right-width: var(--x-border-width);
  margin-right: 1rem;
}

.form-control:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  border-left-width: var(--x-border-width);
  margin-left: 1rem;
}

.form-control:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  border-left-width: var(--x-border-width);
  margin-left: 1rem;
}

.form-control:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  border-left-width: var(--x-border-width);
  margin-left: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }

  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control-sm:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::-webkit-file-upload-button {
  margin-right: .875rem;
}

.form-control-sm:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::file-selector-button {
  margin-right: .875rem;
}

.form-control-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: .875rem;
}

.form-control-sm:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: .875rem;
}

.form-control-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: .875rem;
}

.form-control-lg:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::-webkit-file-upload-button {
  margin-right: 1.25rem;
}

.form-control-lg:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::file-selector-button {
  margin-right: 1.25rem;
}

.form-control-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: 1.25rem;
}

.form-control-lg:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1.25rem;
}

.form-control-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1.25rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn {
  --x-btn-padding-x: 1rem;
  --x-btn-padding-y: .5rem;
  --x-btn-font-family: ;
  --x-btn-font-size: 1rem;
  --x-btn-font-weight: 500;
  --x-btn-line-height: 1.625;
  --x-btn-color: var(--x-body-color);
  --x-btn-bg: transparent;
  --x-btn-border-width: var(--x-border-width);
  --x-btn-border-color: transparent;
  --x-btn-border-radius: var(--x-border-radius);
  --x-btn-hover-border-color: transparent;
  --x-btn-box-shadow: 0 1px 1px rgba(0, 0, 0, .075);
  --x-btn-disabled-opacity: .65;
  --x-btn-focus-box-shadow: 0 0 0 2px rgba(var(--x-btn-focus-shadow-rgb), .5);
  padding: var(--x-btn-padding-y) var(--x-btn-padding-x);
  font-family: var(--x-btn-font-family);
  font-size: var(--x-btn-font-size);
  font-weight: var(--x-btn-font-weight);
  line-height: var(--x-btn-line-height);
  color: var(--x-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--x-btn-border-width) solid var(--x-btn-border-color);
  border-radius: var(--x-btn-border-radius);
  background-color: var(--x-btn-bg);
  box-shadow: var(--x-btn-box-shadow);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--x-btn-hover-color);
  background-color: var(--x-btn-hover-bg);
  border-color: var(--x-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--x-btn-color);
  background-color: var(--x-btn-bg);
  border-color: var(--x-btn-border-color);
}

.btn:focus-visible {
  color: var(--x-btn-hover-color);
  background-color: var(--x-btn-hover-bg);
  border-color: var(--x-btn-hover-border-color);
  box-shadow: var(--x-btn-box-shadow), var(--x-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:focus-visible + .btn {
  border-color: var(--x-btn-hover-border-color);
  box-shadow: var(--x-btn-box-shadow), var(--x-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--x-btn-active-color);
  background-color: var(--x-btn-active-bg);
  border-color: var(--x-btn-active-border-color);
  box-shadow: var(--x-btn-active-shadow);
}

.btn-check:checked + .btn:focus-visible {
  box-shadow: var(--x-btn-active-shadow), var(--x-btn-focus-box-shadow);
}

:not(.btn-check) + .btn:active:focus-visible {
  box-shadow: var(--x-btn-active-shadow), var(--x-btn-focus-box-shadow);
}

.btn:first-child:active:focus-visible {
  box-shadow: var(--x-btn-active-shadow), var(--x-btn-focus-box-shadow);
}

.btn.active:focus-visible {
  box-shadow: var(--x-btn-active-shadow), var(--x-btn-focus-box-shadow);
}

.btn.show:focus-visible {
  box-shadow: var(--x-btn-active-shadow), var(--x-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--x-btn-disabled-color);
  pointer-events: none;
  background-color: var(--x-btn-disabled-bg);
  border-color: var(--x-btn-disabled-border-color);
  opacity: var(--x-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --x-btn-color: #fff;
  --x-btn-bg: #8957ff;
  --x-btn-border-color: #8957ff;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #6e46cc;
  --x-btn-hover-border-color: #6e46cc;
  --x-btn-focus-shadow-rgb: 155, 112, 255;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #6e46cc;
  --x-btn-active-border-color: #6741bf;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #fff;
  --x-btn-disabled-bg: #8957ff;
  --x-btn-disabled-border-color: #8957ff;
}

.btn-secondary {
  --x-btn-color: #000;
  --x-btn-bg: #ff8c00;
  --x-btn-border-color: #ff8c00;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #ffa333;
  --x-btn-hover-border-color: #ff981a;
  --x-btn-focus-shadow-rgb: 217, 119, 0;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #ffa333;
  --x-btn-active-border-color: #ff981a;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #ff8c00;
  --x-btn-disabled-border-color: #ff8c00;
}

.btn-success {
  --x-btn-color: #000;
  --x-btn-bg: #0c8;
  --x-btn-border-color: #0c8;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #33d6a0;
  --x-btn-hover-border-color: #1ad194;
  --x-btn-focus-shadow-rgb: 0, 173, 116;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #33d6a0;
  --x-btn-active-border-color: #1ad194;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #0c8;
  --x-btn-disabled-border-color: #0c8;
}

.btn-info {
  --x-btn-color: #000;
  --x-btn-bg: #00d4ff;
  --x-btn-border-color: #00d4ff;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #3df;
  --x-btn-hover-border-color: #1ad8ff;
  --x-btn-focus-shadow-rgb: 0, 180, 217;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #3df;
  --x-btn-active-border-color: #1ad8ff;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #00d4ff;
  --x-btn-disabled-border-color: #00d4ff;
}

.btn-warning {
  --x-btn-color: #000;
  --x-btn-bg: #ff8c00;
  --x-btn-border-color: #ff8c00;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #ffa333;
  --x-btn-hover-border-color: #ff981a;
  --x-btn-focus-shadow-rgb: 217, 119, 0;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #ffa333;
  --x-btn-active-border-color: #ff981a;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #ff8c00;
  --x-btn-disabled-border-color: #ff8c00;
}

.btn-danger {
  --x-btn-color: #fff;
  --x-btn-bg: #f36;
  --x-btn-border-color: #f36;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #cc2952;
  --x-btn-hover-border-color: #cc2952;
  --x-btn-focus-shadow-rgb: 255, 82, 125;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #cc2952;
  --x-btn-active-border-color: #bf264d;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #fff;
  --x-btn-disabled-bg: #f36;
  --x-btn-disabled-border-color: #f36;
}

.btn-light {
  --x-btn-color: #000;
  --x-btn-bg: #f5f8fb;
  --x-btn-border-color: #f5f8fb;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #c4c6c9;
  --x-btn-hover-border-color: #c4c6c9;
  --x-btn-focus-shadow-rgb: 208, 211, 213;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #c4c6c9;
  --x-btn-active-border-color: #b8babc;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #f5f8fb;
  --x-btn-disabled-border-color: #f5f8fb;
}

.btn-dark {
  --x-btn-color: #fff;
  --x-btn-bg: #0f172a;
  --x-btn-border-color: #0f172a;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #3f4555;
  --x-btn-hover-border-color: #272e3f;
  --x-btn-focus-shadow-rgb: 51, 58, 74;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #3f4555;
  --x-btn-active-border-color: #272e3f;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #fff;
  --x-btn-disabled-bg: #0f172a;
  --x-btn-disabled-border-color: #0f172a;
}

.btn-outline-primary {
  --x-btn-color: #8957ff;
  --x-btn-border-color: #8957ff;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #8957ff;
  --x-btn-hover-border-color: #8957ff;
  --x-btn-focus-shadow-rgb: 137, 87, 255;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #8957ff;
  --x-btn-active-border-color: #8957ff;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #8957ff;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #8957ff;
  --x-gradient: none;
}

.btn-outline-secondary {
  --x-btn-color: #ff8c00;
  --x-btn-border-color: #ff8c00;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #ff8c00;
  --x-btn-hover-border-color: #ff8c00;
  --x-btn-focus-shadow-rgb: 255, 140, 0;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #ff8c00;
  --x-btn-active-border-color: #ff8c00;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #ff8c00;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #ff8c00;
  --x-gradient: none;
}

.btn-outline-success {
  --x-btn-color: #0c8;
  --x-btn-border-color: #0c8;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #0c8;
  --x-btn-hover-border-color: #0c8;
  --x-btn-focus-shadow-rgb: 0, 204, 136;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #0c8;
  --x-btn-active-border-color: #0c8;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #0c8;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #0c8;
  --x-gradient: none;
}

.btn-outline-info {
  --x-btn-color: #00d4ff;
  --x-btn-border-color: #00d4ff;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #00d4ff;
  --x-btn-hover-border-color: #00d4ff;
  --x-btn-focus-shadow-rgb: 0, 212, 255;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #00d4ff;
  --x-btn-active-border-color: #00d4ff;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #00d4ff;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #00d4ff;
  --x-gradient: none;
}

.btn-outline-warning {
  --x-btn-color: #ff8c00;
  --x-btn-border-color: #ff8c00;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #ff8c00;
  --x-btn-hover-border-color: #ff8c00;
  --x-btn-focus-shadow-rgb: 255, 140, 0;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #ff8c00;
  --x-btn-active-border-color: #ff8c00;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #ff8c00;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #ff8c00;
  --x-gradient: none;
}

.btn-outline-danger {
  --x-btn-color: #f36;
  --x-btn-border-color: #f36;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #f36;
  --x-btn-hover-border-color: #f36;
  --x-btn-focus-shadow-rgb: 255, 51, 102;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #f36;
  --x-btn-active-border-color: #f36;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #f36;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #f36;
  --x-gradient: none;
}

.btn-outline-light {
  --x-btn-color: #f5f8fb;
  --x-btn-border-color: #f5f8fb;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #f5f8fb;
  --x-btn-hover-border-color: #f5f8fb;
  --x-btn-focus-shadow-rgb: 245, 248, 251;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #f5f8fb;
  --x-btn-active-border-color: #f5f8fb;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #f5f8fb;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #f5f8fb;
  --x-gradient: none;
}

.btn-outline-dark {
  --x-btn-color: #0f172a;
  --x-btn-border-color: #0f172a;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #0f172a;
  --x-btn-hover-border-color: #0f172a;
  --x-btn-focus-shadow-rgb: 15, 23, 42;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #0f172a;
  --x-btn-active-border-color: #0f172a;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #0f172a;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #0f172a;
  --x-gradient: none;
}

.btn-link {
  --x-btn-font-weight: 400;
  --x-btn-color: var(--x-link-color);
  --x-btn-bg: transparent;
  --x-btn-border-color: transparent;
  --x-btn-hover-color: var(--x-link-hover-color);
  --x-btn-hover-border-color: transparent;
  --x-btn-active-color: var(--x-link-hover-color);
  --x-btn-active-border-color: transparent;
  --x-btn-disabled-color: #475569;
  --x-btn-disabled-border-color: transparent;
  --x-btn-box-shadow: 0 0 0 #000;
  --x-btn-focus-shadow-rgb: 155, 112, 255;
  text-decoration: none;
}

.btn-link:hover {
  text-decoration: none;
}

.btn-link:focus-visible {
  text-decoration: none;
}

.btn-link:focus-visible {
  color: var(--x-btn-color);
}

.btn-link:hover {
  color: var(--x-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --x-btn-padding-y: .75rem;
  --x-btn-padding-x: 1.25rem;
  --x-btn-font-size: 1rem;
  --x-btn-border-radius: var(--x-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --x-btn-padding-y: .375rem;
  --x-btn-padding-x: .875rem;
  --x-btn-font-size: .875rem;
  --x-btn-border-radius: var(--x-border-radius);
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .15s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid rgba(0, 0, 0, 0);
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --x-dropdown-zindex: 1000;
  --x-dropdown-min-width: 12rem;
  --x-dropdown-padding-x: .5rem;
  --x-dropdown-padding-y: .5rem;
  --x-dropdown-spacer: .125rem;
  --x-dropdown-font-size: .875rem;
  --x-dropdown-color: var(--x-body-color);
  --x-dropdown-bg: #fff;
  --x-dropdown-border-color: #ecf0f5;
  --x-dropdown-border-radius: .75rem;
  --x-dropdown-border-width: 1px;
  --x-dropdown-inner-border-radius: calc(.75rem - 1px);
  --x-dropdown-divider-bg: #eef2f6;
  --x-dropdown-divider-margin-y: .5rem;
  --x-dropdown-box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, .1), 0px 0px 1px 0px rgba(10, 22, 70, .06);
  --x-dropdown-link-color: #0f172a;
  --x-dropdown-link-hover-color: #0f172a;
  --x-dropdown-link-hover-bg: #f1f5f9;
  --x-dropdown-link-active-color: #8957ff;
  --x-dropdown-link-active-bg: transparent;
  --x-dropdown-link-disabled-color: var(--x-tertiary-color);
  --x-dropdown-item-padding-x: 1rem;
  --x-dropdown-item-padding-y: .5rem;
  --x-dropdown-header-color: #475569;
  --x-dropdown-header-padding-x: 1rem;
  --x-dropdown-header-padding-y: .5rem;
  z-index: var(--x-dropdown-zindex);
  min-width: var(--x-dropdown-min-width);
  padding: var(--x-dropdown-padding-y) var(--x-dropdown-padding-x);
  font-size: var(--x-dropdown-font-size);
  color: var(--x-dropdown-color);
  text-align: left;
  background-color: var(--x-dropdown-bg);
  border: var(--x-dropdown-border-width) solid var(--x-dropdown-border-color);
  border-radius: var(--x-dropdown-border-radius);
  box-shadow: var(--x-dropdown-box-shadow);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--x-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--x-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid rgba(0, 0, 0, 0);
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--x-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid rgba(0, 0, 0, 0);
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--x-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid rgba(0, 0, 0, 0);
  border-bottom: .3em solid rgba(0, 0, 0, 0);
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  margin: var(--x-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--x-dropdown-divider-bg);
  opacity: 1;
  height: 0;
  overflow: hidden;
}

.dropdown-item {
  padding: var(--x-dropdown-item-padding-y) var(--x-dropdown-item-padding-x);
  clear: both;
  color: var(--x-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  border-radius: var(--x-dropdown-item-border-radius, 0);
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  width: 100%;
  font-weight: 400;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--x-dropdown-link-hover-color);
  background-color: var(--x-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--x-dropdown-link-active-color);
  background-color: var(--x-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--x-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--x-dropdown-header-padding-y) var(--x-dropdown-header-padding-x);
  color: var(--x-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--x-dropdown-item-padding-y) var(--x-dropdown-item-padding-x);
  color: var(--x-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --x-dropdown-color: #cbd5e1;
  --x-dropdown-bg: #1e293b;
  --x-dropdown-border-color: #ecf0f5;
  --x-dropdown-box-shadow: ;
  --x-dropdown-link-color: #cbd5e1;
  --x-dropdown-link-hover-color: #fff;
  --x-dropdown-divider-bg: #eef2f6;
  --x-dropdown-link-hover-bg: rgba(255, 255, 255, .15);
  --x-dropdown-link-active-color: #8957ff;
  --x-dropdown-link-active-bg: transparent;
  --x-dropdown-link-disabled-color: #64748b;
  --x-dropdown-header-color: #64748b;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--x-border-radius);
}

.btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--x-border-width) * -1);
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .65625rem;
  padding-right: .65625rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: .9375rem;
  padding-right: .9375rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: 0 0 rgba(0, 0, 0, 0);
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--x-border-width) * -1);
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --x-nav-link-padding-x: 1rem;
  --x-nav-link-padding-y: .5rem;
  --x-nav-link-font-size: .875rem;
  --x-nav-link-font-weight: 500;
  --x-nav-link-color: var(--x-body-color);
  --x-nav-link-hover-color: #8957ff;
  --x-nav-link-disabled-color: var(--x-secondary-color);
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--x-nav-link-padding-y) var(--x-nav-link-padding-x);
  font-size: var(--x-nav-link-font-size);
  font-weight: var(--x-nav-link-font-weight);
  color: var(--x-nav-link-color);
  background: none;
  border: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--x-nav-link-hover-color);
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(137, 87, 255, .25);
}

.nav-link.disabled, .nav-link:disabled {
  color: var(--x-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --x-nav-tabs-border-width: var(--x-border-width);
  --x-nav-tabs-border-color: var(--x-border-color);
  --x-nav-tabs-border-radius: var(--x-border-radius);
  --x-nav-tabs-link-hover-border-color: var(--x-secondary-bg) var(--x-secondary-bg) var(--x-border-color);
  --x-nav-tabs-link-active-color: var(--x-emphasis-color);
  --x-nav-tabs-link-active-bg: var(--x-body-bg);
  --x-nav-tabs-link-active-border-color: var(--x-border-color) var(--x-border-color) var(--x-body-bg);
  border-bottom: var(--x-nav-tabs-border-width) solid var(--x-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--x-nav-tabs-border-width));
  border: var(--x-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--x-nav-tabs-border-radius);
  border-top-right-radius: var(--x-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--x-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--x-nav-tabs-link-active-color);
  background-color: var(--x-nav-tabs-link-active-bg);
  border-color: var(--x-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--x-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --x-nav-pills-border-radius: var(--x-border-radius);
  --x-nav-pills-link-active-color: #fff;
  --x-nav-pills-link-active-bg: #8957ff;
}

.nav-pills .nav-link {
  border-radius: var(--x-nav-pills-border-radius);
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--x-nav-pills-link-active-color);
  background-color: var(--x-nav-pills-link-active-bg);
}

.nav-underline {
  --x-nav-underline-gap: 1rem;
  --x-nav-underline-border-width: .125rem;
  --x-nav-underline-link-active-color: var(--x-emphasis-color);
  gap: var(--x-nav-underline-gap);
}

.nav-underline .nav-link {
  border-bottom: var(--x-nav-underline-border-width) solid transparent;
  padding-left: 0;
  padding-right: 0;
}

.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentColor;
}

.nav-underline .nav-link.active, .nav-underline .show > .nav-link {
  color: var(--x-nav-underline-link-active-color);
  border-bottom-color: currentColor;
  font-weight: 600;
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --x-navbar-padding-x: 1rem;
  --x-navbar-padding-y: 1rem;
  --x-navbar-color: rgba(var(--x-emphasis-color-rgb), .65);
  --x-navbar-hover-color: rgba(var(--x-emphasis-color-rgb), .8);
  --x-navbar-disabled-color: rgba(var(--x-emphasis-color-rgb), .3);
  --x-navbar-active-color: rgba(var(--x-emphasis-color-rgb), 1);
  --x-navbar-brand-padding-y: .5rem;
  --x-navbar-brand-margin-end: 0;
  --x-navbar-brand-font-size: 1rem;
  --x-navbar-brand-color: rgba(var(--x-emphasis-color-rgb), 1);
  --x-navbar-brand-hover-color: rgba(var(--x-emphasis-color-rgb), 1);
  --x-navbar-nav-link-padding-x: 1rem;
  --x-navbar-toggler-padding-y: .25rem;
  --x-navbar-toggler-padding-x: .375rem;
  --x-navbar-toggler-font-size: 1.25rem;
  --x-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23475569' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  --x-navbar-toggler-border-color: transparent;
  --x-navbar-toggler-border-radius: var(--x-border-radius);
  --x-navbar-toggler-focus-width: 2px;
  --x-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--x-navbar-padding-y) var(--x-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--x-navbar-brand-padding-y);
  padding-bottom: var(--x-navbar-brand-padding-y);
  margin-right: var(--x-navbar-brand-margin-end);
  font-size: var(--x-navbar-brand-font-size);
  color: var(--x-navbar-brand-color);
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--x-navbar-brand-hover-color);
}

.navbar-nav {
  --x-nav-link-padding-x: 0;
  --x-nav-link-padding-y: .5rem;
  --x-nav-link-font-size: .875rem;
  --x-nav-link-font-weight: 500;
  --x-nav-link-color: var(--x-navbar-color);
  --x-nav-link-hover-color: var(--x-navbar-hover-color);
  --x-nav-link-disabled-color: var(--x-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--x-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--x-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
  color: var(--x-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--x-navbar-toggler-padding-y) var(--x-navbar-toggler-padding-x);
  font-size: var(--x-navbar-toggler-font-size);
  color: var(--x-navbar-color);
  border: var(--x-border-width) solid var(--x-navbar-toggler-border-color);
  border-radius: var(--x-navbar-toggler-border-radius);
  transition: var(--x-navbar-toggler-transition);
  background-color: rgba(0, 0, 0, 0);
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--x-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  vertical-align: middle;
  background-image: var(--x-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--x-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--x-navbar-nav-link-padding-x);
    padding-left: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--x-navbar-nav-link-padding-x);
    padding-left: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--x-navbar-nav-link-padding-x);
    padding-left: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--x-navbar-nav-link-padding-x);
    padding-left: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--x-navbar-nav-link-padding-x);
    padding-left: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--x-navbar-nav-link-padding-x);
  padding-left: var(--x-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  box-shadow: none;
  flex-grow: 1;
  transition: none;
  position: static;
  visibility: visible !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0 !important;
  width: auto !important;
  height: auto !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark, .navbar[data-bs-theme="dark"] {
  --x-navbar-color: rgba(255, 255, 255, .55);
  --x-navbar-hover-color: rgba(255, 255, 255, .75);
  --x-navbar-disabled-color: #64748b;
  --x-navbar-active-color: #fff;
  --x-navbar-brand-color: #fff;
  --x-navbar-brand-hover-color: #fff;
  --x-navbar-toggler-border-color: transparent;
  --x-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23e2e8f0' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

[data-bs-theme="dark"] .navbar-toggler-icon {
  --x-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23e2e8f0' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.card {
  --x-card-spacer-y: 1.5rem;
  --x-card-spacer-x: 1.5rem;
  --x-card-title-spacer-y: .5rem;
  --x-card-title-color: var(--x-heading-color);
  --x-card-subtitle-color: ;
  --x-card-border-width: var(--x-border-width);
  --x-card-border-color: var(--x-border-color);
  --x-card-border-radius: var(--x-border-radius-xl);
  --x-card-box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, .04);
  --x-card-inner-border-radius: calc(var(--x-border-radius-xl)  - (var(--x-border-width)));
  --x-card-cap-padding-y: 1.25rem;
  --x-card-cap-padding-x: 1.5rem;
  --x-card-cap-bg: rgba(var(--x-body-color-rgb), .03);
  --x-card-cap-color: var(--x-heading-color);
  --x-card-height: ;
  --x-card-color: ;
  --x-card-bg: var(--x-body-bg);
  --x-card-img-overlay-padding: 1rem;
  --x-card-group-margin: .75rem;
  height: var(--x-card-height);
  color: var(--x-body-color);
  word-wrap: break-word;
  background-color: var(--x-card-bg);
  border: var(--x-card-border-width) solid var(--x-card-border-color);
  border-radius: var(--x-card-border-radius);
  box-shadow: var(--x-card-box-shadow);
  background-clip: border-box;
  flex-direction: column;
  min-width: 0;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--x-card-inner-border-radius);
  border-top-right-radius: var(--x-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--x-card-inner-border-radius);
  border-bottom-left-radius: var(--x-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--x-card-spacer-y) var(--x-card-spacer-x);
  color: var(--x-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--x-card-title-spacer-y);
  color: var(--x-card-title-color);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--x-card-title-spacer-y));
  color: var(--x-card-subtitle-color);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--x-card-spacer-x);
}

.card-header {
  padding: var(--x-card-cap-padding-y) var(--x-card-cap-padding-x);
  color: var(--x-card-cap-color);
  background-color: var(--x-card-cap-bg);
  border-bottom: var(--x-card-border-width) solid var(--x-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--x-card-inner-border-radius) var(--x-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--x-card-cap-padding-y) var(--x-card-cap-padding-x);
  color: var(--x-card-cap-color);
  background-color: var(--x-card-cap-bg);
  border-top: var(--x-card-border-width) solid var(--x-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--x-card-inner-border-radius) var(--x-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--x-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--x-card-cap-padding-y));
  margin-left: calc(-.5 * var(--x-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--x-card-bg);
  border-bottom-color: var(--x-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--x-card-cap-padding-x));
  margin-left: calc(-.5 * var(--x-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--x-card-img-overlay-padding);
  border-radius: var(--x-card-inner-border-radius);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--x-card-inner-border-radius);
  border-top-right-radius: var(--x-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--x-card-inner-border-radius);
  border-bottom-left-radius: var(--x-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--x-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --x-accordion-color: var(--x-body-color);
  --x-accordion-bg: transparent;
  --x-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --x-accordion-border-color: #e2e8f0;
  --x-accordion-border-width: 1px;
  --x-accordion-border-radius: .375rem;
  --x-accordion-inner-border-radius: calc(.375rem - 1px);
  --x-accordion-btn-padding-x: 1.25rem;
  --x-accordion-btn-padding-y: 1.5rem;
  --x-accordion-btn-color: var(--x-body-color);
  --x-accordion-btn-bg: transparent;
  --x-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--x-body-color%29'%3e%3cpath fill-rule='evenodd' d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3e%3c/svg%3e");
  --x-accordion-btn-icon-width: 1.25rem;
  --x-accordion-btn-icon-transform: rotate(180deg);
  --x-accordion-btn-icon-transition: transform .2s ease-in-out;
  --x-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238957FF'%3e%3cpath fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3e%3c/svg%3e");
  --x-accordion-btn-focus-border-color: #e2e8f0;
  --x-accordion-btn-focus-box-shadow: 0 0 0 2px rgba(137, 87, 255, .25);
  --x-accordion-body-padding-x: 1.25rem;
  --x-accordion-body-padding-y: 1.5rem;
  --x-accordion-active-color: #8957ff;
  --x-accordion-active-bg: transparent;
}

.accordion-button {
  padding: var(--x-accordion-btn-padding-y) var(--x-accordion-btn-padding-x);
  color: var(--x-accordion-btn-color);
  text-align: left;
  background-color: var(--x-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--x-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  width: 100%;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--x-accordion-active-color);
  background-color: var(--x-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--x-accordion-border-width)) 0 var(--x-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--x-accordion-btn-active-icon);
  transform: var(--x-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--x-accordion-btn-icon-width);
  height: var(--x-accordion-btn-icon-width);
  content: "";
  background-image: var(--x-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--x-accordion-btn-icon-width);
  transition: var(--x-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--x-accordion-btn-focus-border-color);
  box-shadow: var(--x-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--x-accordion-color);
  background-color: var(--x-accordion-bg);
  border: var(--x-accordion-border-width) solid var(--x-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--x-accordion-border-radius);
  border-top-right-radius: var(--x-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--x-accordion-inner-border-radius);
  border-top-right-radius: var(--x-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--x-accordion-border-radius);
  border-bottom-left-radius: var(--x-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--x-accordion-inner-border-radius);
  border-bottom-left-radius: var(--x-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--x-accordion-border-radius);
  border-bottom-left-radius: var(--x-accordion-border-radius);
}

.accordion-body {
  padding: var(--x-accordion-body-padding-y) var(--x-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button:after {
  --x-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23b89aff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --x-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23b89aff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --x-breadcrumb-padding-x: 0;
  --x-breadcrumb-padding-y: 0;
  --x-breadcrumb-margin-bottom: 0;
  --x-breadcrumb-font-size: .875rem;
  --x-breadcrumb-bg: ;
  --x-breadcrumb-border-radius: ;
  --x-breadcrumb-divider-color: #94a3b8;
  --x-breadcrumb-item-padding-x: .375rem;
  --x-breadcrumb-item-active-color: #0f172a;
  padding: var(--x-breadcrumb-padding-y) var(--x-breadcrumb-padding-x);
  margin-bottom: var(--x-breadcrumb-margin-bottom);
  font-size: var(--x-breadcrumb-font-size);
  background-color: var(--x-breadcrumb-bg);
  border-radius: var(--x-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--x-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--x-breadcrumb-item-padding-x);
  color: var(--x-breadcrumb-divider-color);
  content: var(--x-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--x-breadcrumb-item-active-color);
}

.pagination {
  --x-pagination-padding-x: 1rem;
  --x-pagination-padding-y: .375rem;
  --x-pagination-font-size: .875rem;
  --x-pagination-color: var(--x-body-color-secondary);
  --x-pagination-bg: var(--x-body-bg);
  --x-pagination-border-width: var(--x-border-width);
  --x-pagination-border-color: var(--x-border-color);
  --x-pagination-border-radius: var(--x-border-radius);
  --x-pagination-hover-color: var(--x-link-hover-color);
  --x-pagination-hover-bg: var(--x-tertiary-bg);
  --x-pagination-hover-border-color: var(--x-border-color);
  --x-pagination-focus-color: var(--x-link-hover-color);
  --x-pagination-focus-bg: var(--x-secondary-bg);
  --x-pagination-focus-box-shadow: 0 0 0 .25rem rgba(137, 87, 255, .25);
  --x-pagination-active-color: #fff;
  --x-pagination-active-bg: #8957ff;
  --x-pagination-active-border-color: #8957ff;
  --x-pagination-disabled-color: var(--x-secondary-color);
  --x-pagination-disabled-bg: var(--x-secondary-bg);
  --x-pagination-disabled-border-color: var(--x-border-color);
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--x-pagination-padding-y) var(--x-pagination-padding-x);
  font-size: var(--x-pagination-font-size);
  color: var(--x-pagination-color);
  background-color: var(--x-pagination-bg);
  border: var(--x-pagination-border-width) solid var(--x-pagination-border-color);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--x-pagination-hover-color);
  background-color: var(--x-pagination-hover-bg);
  border-color: var(--x-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--x-pagination-focus-color);
  background-color: var(--x-pagination-focus-bg);
  box-shadow: var(--x-pagination-focus-box-shadow);
  outline: 0;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--x-pagination-active-color);
  background-color: var(--x-pagination-active-bg);
  border-color: var(--x-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--x-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--x-pagination-disabled-bg);
  border-color: var(--x-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--x-border-width) * -1);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--x-pagination-border-radius);
  border-bottom-left-radius: var(--x-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--x-pagination-border-radius);
  border-bottom-right-radius: var(--x-pagination-border-radius);
}

.pagination-lg {
  --x-pagination-padding-x: 1.5rem;
  --x-pagination-padding-y: .75rem;
  --x-pagination-font-size: 1.25rem;
  --x-pagination-border-radius: var(--x-border-radius-lg);
}

.pagination-sm {
  --x-pagination-padding-x: .75rem;
  --x-pagination-padding-y: .25rem;
  --x-pagination-font-size: .875rem;
  --x-pagination-border-radius: var(--x-border-radius-sm);
}

.badge {
  --x-badge-padding-x: .6rem;
  --x-badge-padding-y: .2rem;
  --x-badge-font-size: .75em;
  --x-badge-font-weight: 600;
  --x-badge-color: #fff;
  --x-badge-border-radius: .375rem;
  padding: var(--x-badge-padding-y) var(--x-badge-padding-x);
  font-size: var(--x-badge-font-size);
  font-weight: var(--x-badge-font-weight);
  color: var(--x-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--x-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --x-alert-bg: transparent;
  --x-alert-padding-x: 1rem;
  --x-alert-padding-y: .875rem;
  --x-alert-margin-bottom: 0;
  --x-alert-color: inherit;
  --x-alert-border-color: transparent;
  --x-alert-border: 1px solid var(--x-alert-border-color);
  --x-alert-border-radius: .375rem;
  --x-alert-link-color: inherit;
  padding: var(--x-alert-padding-y) var(--x-alert-padding-x);
  margin-bottom: var(--x-alert-margin-bottom);
  color: var(--x-alert-color);
  background-color: var(--x-alert-bg);
  border: var(--x-alert-border);
  border-radius: var(--x-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  color: var(--x-alert-link-color);
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.09375rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --x-alert-color: var(--x-primary-text-emphasis);
  --x-alert-bg: var(--x-primary-bg-subtle);
  --x-alert-border-color: var(--x-primary-border-subtle);
  --x-alert-link-color: var(--x-primary-text-emphasis);
}

.alert-secondary {
  --x-alert-color: var(--x-secondary-text-emphasis);
  --x-alert-bg: var(--x-secondary-bg-subtle);
  --x-alert-border-color: var(--x-secondary-border-subtle);
  --x-alert-link-color: var(--x-secondary-text-emphasis);
}

.alert-success {
  --x-alert-color: var(--x-success-text-emphasis);
  --x-alert-bg: var(--x-success-bg-subtle);
  --x-alert-border-color: var(--x-success-border-subtle);
  --x-alert-link-color: var(--x-success-text-emphasis);
}

.alert-info {
  --x-alert-color: var(--x-info-text-emphasis);
  --x-alert-bg: var(--x-info-bg-subtle);
  --x-alert-border-color: var(--x-info-border-subtle);
  --x-alert-link-color: var(--x-info-text-emphasis);
}

.alert-warning {
  --x-alert-color: var(--x-warning-text-emphasis);
  --x-alert-bg: var(--x-warning-bg-subtle);
  --x-alert-border-color: var(--x-warning-border-subtle);
  --x-alert-link-color: var(--x-warning-text-emphasis);
}

.alert-danger {
  --x-alert-color: var(--x-danger-text-emphasis);
  --x-alert-bg: var(--x-danger-bg-subtle);
  --x-alert-border-color: var(--x-danger-border-subtle);
  --x-alert-link-color: var(--x-danger-text-emphasis);
}

.alert-light {
  --x-alert-color: var(--x-light-text-emphasis);
  --x-alert-bg: var(--x-light-bg-subtle);
  --x-alert-border-color: var(--x-light-border-subtle);
  --x-alert-link-color: var(--x-light-text-emphasis);
}

.alert-dark {
  --x-alert-color: var(--x-dark-text-emphasis);
  --x-alert-bg: var(--x-dark-bg-subtle);
  --x-alert-border-color: var(--x-dark-border-subtle);
  --x-alert-link-color: var(--x-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: .25rem;
  }
}

.progress, .progress-stacked {
  --x-progress-height: .25rem;
  --x-progress-font-size: .75rem;
  --x-progress-bg: var(--x-secondary-bg);
  --x-progress-border-radius: var(--x-border-radius);
  --x-progress-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-progress-bar-color: #fff;
  --x-progress-bar-bg: #8957ff;
  --x-progress-bar-transition: width .6s ease;
  height: var(--x-progress-height);
  font-size: var(--x-progress-font-size);
  background-color: var(--x-progress-bg);
  border-radius: var(--x-progress-border-radius);
  box-shadow: var(--x-progress-box-shadow);
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: var(--x-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--x-progress-bar-bg);
  transition: var(--x-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
  background-size: var(--x-progress-height) var(--x-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --x-list-group-color: var(--x-body-color);
  --x-list-group-bg: var(--x-body-bg);
  --x-list-group-border-color: var(--x-border-color);
  --x-list-group-border-width: var(--x-border-width);
  --x-list-group-border-radius: var(--x-border-radius);
  --x-list-group-item-padding-x: 1.25rem;
  --x-list-group-item-padding-y: 1.125rem;
  --x-list-group-action-color: var(--x-secondary-color);
  --x-list-group-action-hover-color: var(--x-emphasis-color);
  --x-list-group-action-hover-bg: var(--x-tertiary-bg);
  --x-list-group-action-active-color: var(--x-body-color);
  --x-list-group-action-active-bg: var(--x-secondary-bg);
  --x-list-group-disabled-color: var(--x-secondary-color);
  --x-list-group-disabled-bg: var(--x-body-bg);
  --x-list-group-active-color: #fff;
  --x-list-group-active-bg: #8957ff;
  --x-list-group-active-border-color: #8957ff;
  border-radius: var(--x-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  color: var(--x-list-group-action-color);
  text-align: inherit;
  width: 100%;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--x-list-group-action-hover-color);
  background-color: var(--x-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--x-list-group-action-active-color);
  background-color: var(--x-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--x-list-group-item-padding-y) var(--x-list-group-item-padding-x);
  color: var(--x-list-group-color);
  background-color: var(--x-list-group-bg);
  border: var(--x-list-group-border-width) solid var(--x-list-group-border-color);
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--x-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--x-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--x-list-group-active-color);
  background-color: var(--x-list-group-active-bg);
  border-color: var(--x-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--x-list-group-border-width));
  border-top-width: var(--x-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--x-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--x-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--x-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--x-list-group-border-width));
  border-left-width: var(--x-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--x-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--x-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--x-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--x-list-group-border-width));
    border-left-width: var(--x-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--x-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--x-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--x-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--x-list-group-border-width));
    border-left-width: var(--x-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--x-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--x-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--x-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--x-list-group-border-width));
    border-left-width: var(--x-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--x-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--x-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--x-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--x-list-group-border-width));
    border-left-width: var(--x-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--x-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--x-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--x-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--x-list-group-border-width));
    border-left-width: var(--x-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--x-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --x-list-group-color: var(--x-primary-text-emphasis);
  --x-list-group-bg: var(--x-primary-bg-subtle);
  --x-list-group-border-color: var(--x-primary-border-subtle);
  --x-list-group-action-hover-color: var(--x-emphasis-color);
  --x-list-group-action-hover-bg: var(--x-primary-border-subtle);
  --x-list-group-action-active-color: var(--x-emphasis-color);
  --x-list-group-action-active-bg: var(--x-primary-border-subtle);
  --x-list-group-active-color: var(--x-primary-bg-subtle);
  --x-list-group-active-bg: var(--x-primary-text-emphasis);
  --x-list-group-active-border-color: var(--x-primary-text-emphasis);
}

.list-group-item-secondary {
  --x-list-group-color: var(--x-secondary-text-emphasis);
  --x-list-group-bg: var(--x-secondary-bg-subtle);
  --x-list-group-border-color: var(--x-secondary-border-subtle);
  --x-list-group-action-hover-color: var(--x-emphasis-color);
  --x-list-group-action-hover-bg: var(--x-secondary-border-subtle);
  --x-list-group-action-active-color: var(--x-emphasis-color);
  --x-list-group-action-active-bg: var(--x-secondary-border-subtle);
  --x-list-group-active-color: var(--x-secondary-bg-subtle);
  --x-list-group-active-bg: var(--x-secondary-text-emphasis);
  --x-list-group-active-border-color: var(--x-secondary-text-emphasis);
}

.list-group-item-success {
  --x-list-group-color: var(--x-success-text-emphasis);
  --x-list-group-bg: var(--x-success-bg-subtle);
  --x-list-group-border-color: var(--x-success-border-subtle);
  --x-list-group-action-hover-color: var(--x-emphasis-color);
  --x-list-group-action-hover-bg: var(--x-success-border-subtle);
  --x-list-group-action-active-color: var(--x-emphasis-color);
  --x-list-group-action-active-bg: var(--x-success-border-subtle);
  --x-list-group-active-color: var(--x-success-bg-subtle);
  --x-list-group-active-bg: var(--x-success-text-emphasis);
  --x-list-group-active-border-color: var(--x-success-text-emphasis);
}

.list-group-item-info {
  --x-list-group-color: var(--x-info-text-emphasis);
  --x-list-group-bg: var(--x-info-bg-subtle);
  --x-list-group-border-color: var(--x-info-border-subtle);
  --x-list-group-action-hover-color: var(--x-emphasis-color);
  --x-list-group-action-hover-bg: var(--x-info-border-subtle);
  --x-list-group-action-active-color: var(--x-emphasis-color);
  --x-list-group-action-active-bg: var(--x-info-border-subtle);
  --x-list-group-active-color: var(--x-info-bg-subtle);
  --x-list-group-active-bg: var(--x-info-text-emphasis);
  --x-list-group-active-border-color: var(--x-info-text-emphasis);
}

.list-group-item-warning {
  --x-list-group-color: var(--x-warning-text-emphasis);
  --x-list-group-bg: var(--x-warning-bg-subtle);
  --x-list-group-border-color: var(--x-warning-border-subtle);
  --x-list-group-action-hover-color: var(--x-emphasis-color);
  --x-list-group-action-hover-bg: var(--x-warning-border-subtle);
  --x-list-group-action-active-color: var(--x-emphasis-color);
  --x-list-group-action-active-bg: var(--x-warning-border-subtle);
  --x-list-group-active-color: var(--x-warning-bg-subtle);
  --x-list-group-active-bg: var(--x-warning-text-emphasis);
  --x-list-group-active-border-color: var(--x-warning-text-emphasis);
}

.list-group-item-danger {
  --x-list-group-color: var(--x-danger-text-emphasis);
  --x-list-group-bg: var(--x-danger-bg-subtle);
  --x-list-group-border-color: var(--x-danger-border-subtle);
  --x-list-group-action-hover-color: var(--x-emphasis-color);
  --x-list-group-action-hover-bg: var(--x-danger-border-subtle);
  --x-list-group-action-active-color: var(--x-emphasis-color);
  --x-list-group-action-active-bg: var(--x-danger-border-subtle);
  --x-list-group-active-color: var(--x-danger-bg-subtle);
  --x-list-group-active-bg: var(--x-danger-text-emphasis);
  --x-list-group-active-border-color: var(--x-danger-text-emphasis);
}

.list-group-item-light {
  --x-list-group-color: var(--x-light-text-emphasis);
  --x-list-group-bg: var(--x-light-bg-subtle);
  --x-list-group-border-color: var(--x-light-border-subtle);
  --x-list-group-action-hover-color: var(--x-emphasis-color);
  --x-list-group-action-hover-bg: var(--x-light-border-subtle);
  --x-list-group-action-active-color: var(--x-emphasis-color);
  --x-list-group-action-active-bg: var(--x-light-border-subtle);
  --x-list-group-active-color: var(--x-light-bg-subtle);
  --x-list-group-active-bg: var(--x-light-text-emphasis);
  --x-list-group-active-border-color: var(--x-light-text-emphasis);
}

.list-group-item-dark {
  --x-list-group-color: var(--x-dark-text-emphasis);
  --x-list-group-bg: var(--x-dark-bg-subtle);
  --x-list-group-border-color: var(--x-dark-border-subtle);
  --x-list-group-action-hover-color: var(--x-emphasis-color);
  --x-list-group-action-hover-bg: var(--x-dark-border-subtle);
  --x-list-group-action-active-color: var(--x-emphasis-color);
  --x-list-group-action-active-bg: var(--x-dark-border-subtle);
  --x-list-group-active-color: var(--x-dark-bg-subtle);
  --x-list-group-active-bg: var(--x-dark-text-emphasis);
  --x-list-group-active-border-color: var(--x-dark-text-emphasis);
}

.btn-close {
  --x-btn-close-color: currentColor;
  --x-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='currentColor'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  --x-btn-close-opacity: .5;
  --x-btn-close-hover-opacity: .75;
  --x-btn-close-focus-shadow: 0 0 0 2px rgba(137, 87, 255, .25);
  --x-btn-close-focus-opacity: 1;
  --x-btn-close-disabled-opacity: .25;
  --x-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  color: var(--x-btn-close-color);
  background: transparent var(--x-btn-close-bg) center / 1em auto no-repeat;
  opacity: var(--x-btn-close-opacity);
  border: 0;
  border-radius: .375rem;
  width: 1em;
  height: 1em;
  padding: .25em;
}

.btn-close:hover {
  color: var(--x-btn-close-color);
  opacity: var(--x-btn-close-hover-opacity);
  text-decoration: none;
}

.btn-close:focus {
  box-shadow: var(--x-btn-close-focus-shadow);
  opacity: var(--x-btn-close-focus-opacity);
  outline: 0;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: var(--x-btn-close-disabled-opacity);
}

.btn-close-white, [data-bs-theme="dark"] .btn-close {
  filter: var(--x-btn-close-white-filter);
}

.toast {
  --x-toast-zindex: 1090;
  --x-toast-padding-x: .75rem;
  --x-toast-padding-y: .5rem;
  --x-toast-spacing: 1.5rem;
  --x-toast-max-width: 350px;
  --x-toast-font-size: .875rem;
  --x-toast-color: ;
  --x-toast-bg: rgba(var(--x-body-bg-rgb), .85);
  --x-toast-border-width: var(--x-border-width);
  --x-toast-border-color: var(--x-border-color-translucent);
  --x-toast-border-radius: var(--x-border-radius);
  --x-toast-box-shadow: var(--x-box-shadow);
  --x-toast-header-color: var(--x-secondary-color);
  --x-toast-header-bg: rgba(var(--x-body-bg-rgb), .85);
  --x-toast-header-border-color: var(--x-border-color-translucent);
  width: var(--x-toast-max-width);
  font-size: var(--x-toast-font-size);
  color: var(--x-toast-color);
  pointer-events: auto;
  background-color: var(--x-toast-bg);
  border: var(--x-toast-border-width) solid var(--x-toast-border-color);
  box-shadow: var(--x-toast-box-shadow);
  border-radius: var(--x-toast-border-radius);
  background-clip: padding-box;
  max-width: 100%;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --x-toast-zindex: 1090;
  z-index: var(--x-toast-zindex);
  pointer-events: none;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--x-toast-spacing);
}

.toast-header {
  padding: var(--x-toast-padding-y) var(--x-toast-padding-x);
  color: var(--x-toast-header-color);
  background-color: var(--x-toast-header-bg);
  border-bottom: var(--x-toast-border-width) solid var(--x-toast-header-border-color);
  border-top-left-radius: calc(var(--x-toast-border-radius)  - var(--x-toast-border-width));
  border-top-right-radius: calc(var(--x-toast-border-radius)  - var(--x-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--x-toast-padding-x));
  margin-left: var(--x-toast-padding-x);
}

.toast-body {
  padding: var(--x-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --x-modal-zindex: 1055;
  --x-modal-width: 500px;
  --x-modal-padding: 1.5rem;
  --x-modal-margin: .5rem;
  --x-modal-color: ;
  --x-modal-bg: var(--x-body-bg);
  --x-modal-border-color: var(--x-border-color);
  --x-modal-border-width: var(--x-border-width);
  --x-modal-border-radius: var(--x-border-radius-lg);
  --x-modal-box-shadow: var(--x-box-shadow-sm);
  --x-modal-inner-border-radius: calc(var(--x-border-radius-lg)  - (var(--x-border-width)));
  --x-modal-header-padding-x: 1.5rem;
  --x-modal-header-padding-y: 1.5rem;
  --x-modal-header-padding: 1.5rem 1.5rem;
  --x-modal-header-border-color: var(--x-border-color);
  --x-modal-header-border-width: var(--x-border-width);
  --x-modal-title-line-height: 1.625;
  --x-modal-footer-gap: .75rem;
  --x-modal-footer-bg: ;
  --x-modal-footer-border-color: var(--x-border-color);
  --x-modal-footer-border-width: var(--x-border-width);
  z-index: var(--x-modal-zindex);
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  margin: var(--x-modal-margin);
  pointer-events: none;
  width: auto;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: all .15s ease-out;
  transform: translate(0, 15px)scale(.95);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--x-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - var(--x-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.modal-content {
  color: var(--x-modal-color);
  pointer-events: auto;
  background-color: var(--x-modal-bg);
  border: var(--x-modal-border-width) solid var(--x-modal-border-color);
  border-radius: var(--x-modal-border-radius);
  box-shadow: var(--x-modal-box-shadow);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --x-backdrop-zindex: 1050;
  --x-backdrop-bg: rgba(15, 23, 42, .25);
  --x-backdrop-opacity: 1;
  z-index: var(--x-backdrop-zindex);
  background-color: var(--x-backdrop-bg);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--x-backdrop-opacity);
}

.modal-header {
  padding: var(--x-modal-header-padding);
  border-bottom: var(--x-modal-header-border-width) solid var(--x-modal-header-border-color);
  border-top-left-radius: var(--x-modal-inner-border-radius);
  border-top-right-radius: var(--x-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--x-modal-header-padding-y) * .5) calc(var(--x-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--x-modal-header-padding-y)) calc(-.5 * var(--x-modal-header-padding-x)) calc(-.5 * var(--x-modal-header-padding-y)) auto;
}

.modal-title {
  line-height: var(--x-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--x-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--x-modal-padding)  - var(--x-modal-footer-gap) * .5);
  background-color: var(--x-modal-footer-bg);
  border-top: var(--x-modal-footer-border-width) solid var(--x-modal-footer-border-color);
  border-bottom-right-radius: var(--x-modal-inner-border-radius);
  border-bottom-left-radius: var(--x-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--x-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --x-modal-margin: 1.75rem;
    --x-modal-box-shadow: var(--x-box-shadow);
  }

  .modal-dialog {
    max-width: var(--x-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --x-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    --x-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --x-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.modal-fullscreen .modal-header, .modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xxl-down .modal-header, .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --x-tooltip-zindex: 1080;
  --x-tooltip-max-width: 200px;
  --x-tooltip-padding-x: .5rem;
  --x-tooltip-padding-y: .25rem;
  --x-tooltip-margin: ;
  --x-tooltip-font-size: .875rem;
  --x-tooltip-color: var(--x-body-bg);
  --x-tooltip-bg: var(--x-emphasis-color);
  --x-tooltip-border-radius: var(--x-border-radius);
  --x-tooltip-opacity: .9;
  --x-tooltip-arrow-width: .8rem;
  --x-tooltip-arrow-height: .4rem;
  z-index: var(--x-tooltip-zindex);
  margin: var(--x-tooltip-margin);
  font-family: var(--x-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625;
  font-size: var(--x-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip.show {
  opacity: var(--x-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  width: var(--x-tooltip-arrow-width);
  height: var(--x-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  position: absolute;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--x-tooltip-arrow-height));
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: var(--x-tooltip-arrow-height) calc(var(--x-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--x-tooltip-bg);
  top: -1px;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--x-tooltip-arrow-height));
  width: var(--x-tooltip-arrow-height);
  height: var(--x-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: calc(var(--x-tooltip-arrow-width) * .5) var(--x-tooltip-arrow-height) calc(var(--x-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--x-tooltip-bg);
  right: -1px;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--x-tooltip-arrow-height));
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 calc(var(--x-tooltip-arrow-width) * .5) var(--x-tooltip-arrow-height);
  border-bottom-color: var(--x-tooltip-bg);
  bottom: -1px;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--x-tooltip-arrow-height));
  width: var(--x-tooltip-arrow-height);
  height: var(--x-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: calc(var(--x-tooltip-arrow-width) * .5) 0 calc(var(--x-tooltip-arrow-width) * .5) var(--x-tooltip-arrow-height);
  border-left-color: var(--x-tooltip-bg);
  left: -1px;
}

.tooltip-inner {
  max-width: var(--x-tooltip-max-width);
  padding: var(--x-tooltip-padding-y) var(--x-tooltip-padding-x);
  color: var(--x-tooltip-color);
  text-align: center;
  background-color: var(--x-tooltip-bg);
  border-radius: var(--x-tooltip-border-radius);
}

.popover {
  --x-popover-zindex: 1070;
  --x-popover-max-width: 276px;
  --x-popover-font-size: .875rem;
  --x-popover-bg: #fff;
  --x-popover-border-width: 1px;
  --x-popover-border-color: rgba(0, 0, 0, .05);
  --x-popover-border-radius: .5rem;
  --x-popover-inner-border-radius: calc(.5rem - 1px);
  --x-popover-box-shadow: 0px .5rem 2rem 0px rgba(0, 0, 0, .2);
  --x-popover-header-padding-x: .75rem;
  --x-popover-header-padding-y: .75rem;
  --x-popover-header-font-size: 1rem;
  --x-popover-header-color: #0f172a;
  --x-popover-header-bg: #fff;
  --x-popover-body-padding-x: .75rem;
  --x-popover-body-padding-y: .75rem;
  --x-popover-body-color: var(--x-body-color);
  --x-popover-arrow-width: 1rem;
  --x-popover-arrow-height: .5rem;
  --x-popover-arrow-border: var(--x-popover-border-color);
  z-index: var(--x-popover-zindex);
  max-width: var(--x-popover-max-width);
  font-family: var(--x-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625;
  font-size: var(--x-popover-font-size);
  word-wrap: break-word;
  background-color: var(--x-popover-bg);
  border: var(--x-popover-border-width) solid var(--x-popover-border-color);
  border-radius: var(--x-popover-border-radius);
  box-shadow: var(--x-popover-box-shadow);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--x-popover-arrow-width);
  height: var(--x-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid rgba(0, 0, 0, 0);
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--x-popover-arrow-height))  - var(--x-popover-border-width));
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: var(--x-popover-arrow-height) calc(var(--x-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-top-color: var(--x-popover-arrow-border);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  bottom: var(--x-popover-border-width);
  border-top-color: var(--x-popover-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--x-popover-arrow-height))  - var(--x-popover-border-width));
  width: var(--x-popover-arrow-height);
  height: var(--x-popover-arrow-width);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: calc(var(--x-popover-arrow-width) * .5) var(--x-popover-arrow-height) calc(var(--x-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-right-color: var(--x-popover-arrow-border);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  left: var(--x-popover-border-width);
  border-right-color: var(--x-popover-bg);
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--x-popover-arrow-height))  - var(--x-popover-border-width));
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 calc(var(--x-popover-arrow-width) * .5) var(--x-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-bottom-color: var(--x-popover-arrow-border);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  top: var(--x-popover-border-width);
  border-bottom-color: var(--x-popover-bg);
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: var(--x-popover-arrow-width);
  margin-left: calc(-.5 * var(--x-popover-arrow-width));
  content: "";
  border-bottom: var(--x-popover-border-width) solid var(--x-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--x-popover-arrow-height))  - var(--x-popover-border-width));
  width: var(--x-popover-arrow-height);
  height: var(--x-popover-arrow-width);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: calc(var(--x-popover-arrow-width) * .5) 0 calc(var(--x-popover-arrow-width) * .5) var(--x-popover-arrow-height);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-left-color: var(--x-popover-arrow-border);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  right: var(--x-popover-border-width);
  border-left-color: var(--x-popover-bg);
}

.popover-header {
  padding: var(--x-popover-header-padding-y) var(--x-popover-header-padding-x);
  font-size: var(--x-popover-header-font-size);
  color: var(--x-popover-header-color);
  background-color: var(--x-popover-header-bg);
  border-bottom: var(--x-popover-border-width) solid var(--x-popover-border-color);
  border-top-left-radius: var(--x-popover-inner-border-radius);
  border-top-right-radius: var(--x-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--x-popover-body-padding-y) var(--x-popover-body-padding-x);
  color: var(--x-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  width: 15%;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2rem;
  height: 2rem;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid rgba(0, 0, 0, 0);
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  width: 30px;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon, [data-bs-theme="dark"] .carousel .carousel-control-next-icon, [data-bs-theme="dark"].carousel .carousel-control-prev-icon, [data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-caption, [data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}

.spinner-grow, .spinner-border {
  width: var(--x-spinner-width);
  height: var(--x-spinner-height);
  vertical-align: var(--x-spinner-vertical-align);
  animation: var(--x-spinner-animation-speed) linear infinite var(--x-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --x-spinner-width: 2rem;
  --x-spinner-height: 2rem;
  --x-spinner-vertical-align: -.125em;
  --x-spinner-border-width: .25em;
  --x-spinner-animation-speed: .75s;
  --x-spinner-animation-name: spinner-border;
  border: var(--x-spinner-border-width) solid currentcolor;
  border-right-color: rgba(0, 0, 0, 0);
}

.spinner-border-sm {
  --x-spinner-width: 1rem;
  --x-spinner-height: 1rem;
  --x-spinner-border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --x-spinner-width: 2rem;
  --x-spinner-height: 2rem;
  --x-spinner-vertical-align: -.125em;
  --x-spinner-animation-speed: .75s;
  --x-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --x-spinner-width: 1rem;
  --x-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --x-spinner-animation-speed: 1.5s;
  }
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --x-offcanvas-zindex: 1045;
  --x-offcanvas-width: 400px;
  --x-offcanvas-height: 30vh;
  --x-offcanvas-padding-x: 1.5rem;
  --x-offcanvas-padding-y: 1.5rem;
  --x-offcanvas-color: var(--x-body-color);
  --x-offcanvas-bg: var(--x-body-bg);
  --x-offcanvas-border-width: var(--x-border-width);
  --x-offcanvas-border-color: var(--x-border-color);
  --x-offcanvas-box-shadow: var(--x-box-shadow-sm);
  --x-offcanvas-transition: transform .3s ease-in-out;
  --x-offcanvas-title-line-height: 1.625;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    z-index: var(--x-offcanvas-zindex);
    color: var(--x-offcanvas-color);
    visibility: hidden;
    background-color: var(--x-offcanvas-bg);
    box-shadow: var(--x-offcanvas-box-shadow);
    transition: var(--x-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    width: var(--x-offcanvas-width);
    border-right: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--x-offcanvas-width);
    border-left: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    height: var(--x-offcanvas-height);
    border-bottom: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    height: var(--x-offcanvas-height);
    border-top: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --x-offcanvas-height: auto;
    --x-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    z-index: var(--x-offcanvas-zindex);
    color: var(--x-offcanvas-color);
    visibility: hidden;
    background-color: var(--x-offcanvas-bg);
    box-shadow: var(--x-offcanvas-box-shadow);
    transition: var(--x-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    width: var(--x-offcanvas-width);
    border-right: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--x-offcanvas-width);
    border-left: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    height: var(--x-offcanvas-height);
    border-bottom: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    height: var(--x-offcanvas-height);
    border-top: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --x-offcanvas-height: auto;
    --x-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    z-index: var(--x-offcanvas-zindex);
    color: var(--x-offcanvas-color);
    visibility: hidden;
    background-color: var(--x-offcanvas-bg);
    box-shadow: var(--x-offcanvas-box-shadow);
    transition: var(--x-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    width: var(--x-offcanvas-width);
    border-right: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--x-offcanvas-width);
    border-left: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    height: var(--x-offcanvas-height);
    border-bottom: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    height: var(--x-offcanvas-height);
    border-top: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --x-offcanvas-height: auto;
    --x-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    z-index: var(--x-offcanvas-zindex);
    color: var(--x-offcanvas-color);
    visibility: hidden;
    background-color: var(--x-offcanvas-bg);
    box-shadow: var(--x-offcanvas-box-shadow);
    transition: var(--x-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    width: var(--x-offcanvas-width);
    border-right: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--x-offcanvas-width);
    border-left: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    height: var(--x-offcanvas-height);
    border-bottom: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    height: var(--x-offcanvas-height);
    border-top: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --x-offcanvas-height: auto;
    --x-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    z-index: var(--x-offcanvas-zindex);
    color: var(--x-offcanvas-color);
    visibility: hidden;
    background-color: var(--x-offcanvas-bg);
    box-shadow: var(--x-offcanvas-box-shadow);
    transition: var(--x-offcanvas-transition);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    width: var(--x-offcanvas-width);
    border-right: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    width: var(--x-offcanvas-width);
    border-left: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    height: var(--x-offcanvas-height);
    border-bottom: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    height: var(--x-offcanvas-height);
    border-top: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --x-offcanvas-height: auto;
    --x-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

.offcanvas {
  z-index: var(--x-offcanvas-zindex);
  color: var(--x-offcanvas-color);
  visibility: hidden;
  background-color: var(--x-offcanvas-bg);
  box-shadow: var(--x-offcanvas-box-shadow);
  transition: var(--x-offcanvas-transition);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  max-width: 100%;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--x-offcanvas-width);
  border-right: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--x-offcanvas-width);
  border-left: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  height: var(--x-offcanvas-height);
  border-bottom: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  height: var(--x-offcanvas-height);
  border-top: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
  max-height: 100%;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  background-color: rgba(15, 23, 42, .25);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 1;
}

.offcanvas-header {
  padding: var(--x-offcanvas-padding-y) var(--x-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--x-offcanvas-padding-y) * .5) calc(var(--x-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--x-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--x-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--x-offcanvas-padding-y));
}

.offcanvas-title {
  line-height: var(--x-offcanvas-title-line-height);
  margin-bottom: 0;
}

.offcanvas-body {
  padding: var(--x-offcanvas-padding-y) var(--x-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.placeholder {
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  min-height: 1em;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: 2s ease-in-out infinite placeholder-glow;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  animation: 2s linear infinite placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, .8) 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, .8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.avatar {
  --x-avatar-size: 2.5rem;
  --x-avatar-border-radius: .375rem;
  --x-avatar-font-size: 1rem;
  --x-avatar-font-weight: 600;
  vertical-align: middle;
  font-weight: var(--x-avatar-font-weight);
  height: var(--x-avatar-size);
  width: var(--x-avatar-size);
  font-size: var(--x-avatar-font-size);
  border-radius: var(--x-avatar-border-radius);
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.avatar img {
  border-radius: inherit;
  width: 100%;
}

.avatar + .avatar {
  margin-left: .25rem;
}

.avatar + .avatar-content {
  margin-left: .75rem;
  display: inline-block;
}

.avatar-xl {
  --x-avatar-size: 3.5rem;
  --x-avatar-font-size: 1.375rem;
}

.avatar-lg {
  --x-avatar-size: 3rem;
  --x-avatar-font-size: 1.25rem;
}

.avatar-sm {
  --x-avatar-size: 2rem;
  --x-avatar-font-size: .75rem;
  --x-avatar-border-radius: .25rem;
}

.avatar-xs {
  --x-avatar-size: 1.5rem;
  --x-avatar-font-size: .675rem;
  --x-avatar-border-radius: .25rem;
}

.avatar-group {
  line-height: 1;
  display: inline-block;
}

.avatar-group .avatar {
  z-index: 1;
  transition: margin .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .avatar-group .avatar {
    transition: none;
  }
}

.avatar-group .avatar:hover {
  z-index: 2;
}

.avatar-group .avatar + .avatar {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1rem;
}

.avatar-group .avatar-sm + .avatar-sm, .avatar-group .avatar-xs + .avatar-xs {
  margin-left: -.75rem;
}

.avatar-group:hover .avatar {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.avatar-group:hover .avatar-sm {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.avatar-ungroup-hover:hover .avatar:not(:first-child) {
  margin-left: 0;
}

.badge {
  --x-badge-text-transform: ;
  --x-badge-line-height: 1rem;
  text-transform: var(--x-badge-text-transform);
  line-height: var(--x-badge-line-height);
}

.badge-xs {
  --x-badge-padding-x: .5rem;
  --x-badge-padding-y: .1rem;
  font-size: 60%;
}

.badge-md {
  --x-badge-padding-x: .675rem;
  --x-badge-padding-y: .375rem;
}

.badge-lg {
  --x-badge-padding-x: 1rem;
  --x-badge-padding-y: .875rem;
  font-size: 1em;
}

.badge-dot {
  --x-badge-color: var(--x-body-color);
  --x-badge-font-weight: 400;
  font-weight: var(--x-badge-font-weight);
  color: var(--x-badge-color);
  background: none;
  align-items: center;
  padding: 0;
  display: inline-flex;
}

.badge-dot i {
  vertical-align: middle;
  border-radius: 50%;
  width: .375rem;
  height: .375rem;
  margin-right: .5rem;
  display: inline-block;
}

.badge-dot.badge-md i {
  width: .5rem;
  height: .5rem;
}

.badge-dot.badge-lg i {
  width: .625rem;
  height: .625rem;
}

.badge-count {
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  font-size: .75rem;
  font-weight: 600;
  display: inline-flex;
  padding: 0 !important;
}

.badge-count.badge-xs {
  width: 1rem;
  height: 1rem;
  font-size: .5rem;
}

.badge-count.badge-md {
  width: 1.5rem;
  height: 1.5rem;
}

.badge-count.badge-lg {
  width: 2rem;
  height: 2rem;
}

.badge-count svg {
  margin: 0;
}

.btn-soft-primary {
  --x-btn-color: #8957ff;
  --x-btn-bg: #dccdff;
  --x-btn-border-color: #dccdff;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #8957ff;
  --x-btn-hover-border-color: #fff;
  --x-btn-focus-shadow-rgb: 208, 187, 255;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #e3d7ff;
  --x-btn-active-border-color: #e0d2ff;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #dccdff;
  --x-btn-disabled-border-color: #dccdff;
}

.btn-soft-secondary {
  --x-btn-color: #ff8c00;
  --x-btn-bg: #ffddb3;
  --x-btn-border-color: #ffddb3;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #ff8c00;
  --x-btn-hover-border-color: #000;
  --x-btn-focus-shadow-rgb: 255, 209, 152;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #ffe4c2;
  --x-btn-active-border-color: #ffe0bb;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #ffddb3;
  --x-btn-disabled-border-color: #ffddb3;
}

.btn-soft-success {
  --x-btn-color: #0c8;
  --x-btn-bg: #b3f0db;
  --x-btn-border-color: #b3f0db;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #0c8;
  --x-btn-hover-border-color: #000;
  --x-btn-focus-shadow-rgb: 152, 235, 207;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #c2f3e2;
  --x-btn-active-border-color: #bbf2df;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #b3f0db;
  --x-btn-disabled-border-color: #b3f0db;
}

.btn-soft-info {
  --x-btn-color: #00d4ff;
  --x-btn-bg: #b3f2ff;
  --x-btn-border-color: #b3f2ff;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #00d4ff;
  --x-btn-hover-border-color: #000;
  --x-btn-focus-shadow-rgb: 152, 238, 255;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #c2f5ff;
  --x-btn-active-border-color: #bbf3ff;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #b3f2ff;
  --x-btn-disabled-border-color: #b3f2ff;
}

.btn-soft-warning {
  --x-btn-color: #ff8c00;
  --x-btn-bg: #ffddb3;
  --x-btn-border-color: #ffddb3;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #ff8c00;
  --x-btn-hover-border-color: #000;
  --x-btn-focus-shadow-rgb: 255, 209, 152;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #ffe4c2;
  --x-btn-active-border-color: #ffe0bb;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #ffddb3;
  --x-btn-disabled-border-color: #ffddb3;
}

.btn-soft-danger {
  --x-btn-color: #f36;
  --x-btn-bg: #ffc2d1;
  --x-btn-border-color: #ffc2d1;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #f36;
  --x-btn-hover-border-color: #fff;
  --x-btn-focus-shadow-rgb: 255, 173, 193;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #ffceda;
  --x-btn-active-border-color: #ffc8d6;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #ffc2d1;
  --x-btn-disabled-border-color: #ffc2d1;
}

.btn-soft-light {
  --x-btn-color: #f5f8fb;
  --x-btn-bg: #fcfdfe;
  --x-btn-border-color: #fcfdfe;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #f5f8fb;
  --x-btn-hover-border-color: #000;
  --x-btn-focus-shadow-rgb: 251, 252, 254;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #fdfdfe;
  --x-btn-active-border-color: #fcfdfe;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #fcfdfe;
  --x-btn-disabled-border-color: #fcfdfe;
}

.btn-soft-dark {
  --x-btn-color: #0f172a;
  --x-btn-bg: #b7b9bf;
  --x-btn-border-color: #b7b9bf;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #0f172a;
  --x-btn-hover-border-color: #fff;
  --x-btn-focus-shadow-rgb: 158, 161, 169;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #c5c7cc;
  --x-btn-active-border-color: #bec0c5;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #b7b9bf;
  --x-btn-disabled-border-color: #b7b9bf;
}

.btn-neutral {
  --x-btn-color: #334155;
  --x-btn-bg: #fff;
  --x-btn-border-color: #cbd5e1;
  --x-btn-hover-color: #334155;
  --x-btn-hover-bg: #f1f5f9;
  --x-btn-hover-border-color: #e2e8f0;
  --x-btn-focus-shadow-rgb: 180, 191, 204;
  --x-btn-active-color: #334155;
  --x-btn-active-bg: #e2e8f0;
  --x-btn-active-border-color: #cbd5e1;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #fff;
  --x-btn-disabled-border-color: #cbd5e1;
}

.btn-white {
  --x-btn-color: #0f172a;
  --x-btn-bg: #fff;
  --x-btn-border-color: #fff;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #8957ff;
  --x-btn-hover-border-color: #8957ff;
  --x-btn-focus-shadow-rgb: 219, 220, 223;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #8957ff;
  --x-btn-active-border-color: #8957ff;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #fff;
  --x-btn-disabled-border-color: #fff;
}

.btn-xs {
  --x-btn-padding-y: .25rem;
  --x-btn-padding-x: .75rem;
  --x-btn-font-size: .875rem;
}

.btn-square {
  --x-btn-size: 2.75rem;
  width: var(--x-btn-size);
  height: var(--x-btn-size);
  justify-content: center;
  align-items: center;
  padding: 0;
  display: inline-flex;
}

.btn-square.btn-xs {
  --x-btn-size: 2.04688rem;
}

.btn-square.btn-sm, .btn-group-sm > .btn-square.btn {
  --x-btn-size: 2.29688rem;
}

.btn-square.btn-lg, .btn-group-lg > .btn-square.btn {
  --x-btn-size: 3.25rem;
}

.btn-square.btn-xl {
  --x-btn-size: 4rem;
}

.btn-animated {
  position: relative;
  overflow: hidden;
}

.btn-animated .btn-inner-visible {
  position: relative;
}

.btn-animated .btn-inner-hidden {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.btn-animated-x .btn-inner-visible {
  transition: right .3s;
  right: 0;
}

@media (prefers-reduced-motion: reduce) {
  .btn-animated-x .btn-inner-visible {
    transition: none;
  }
}

.btn-animated-x .btn-inner-hidden {
  transition: right .3s;
  right: -100%;
}

@media (prefers-reduced-motion: reduce) {
  .btn-animated-x .btn-inner-hidden {
    transition: none;
  }
}

.btn-animated-x:hover .btn-inner-hidden {
  right: 0;
}

.btn-animated-x:hover .btn-inner-visible {
  right: 150%;
}

.btn-animated-y .btn-inner-visible {
  transition: top .3s;
  top: 0;
}

@media (prefers-reduced-motion: reduce) {
  .btn-animated-y .btn-inner-visible {
    transition: none;
  }
}

.btn-animated-y .btn-inner-hidden {
  transition: top .3s;
  top: -100%;
  left: 0;
}

@media (prefers-reduced-motion: reduce) {
  .btn-animated-y .btn-inner-hidden {
    transition: none;
  }
}

.btn-animated-y:hover .btn-inner-hidden {
  top: 50%;
}

.btn-animated-y:hover .btn-inner-visible {
  top: 100px;
}

.card-comment-box {
  width: 100%;
}

.card-comment-box input, .card-comment-box textarea {
  box-shadow: none;
  color: #1e293b;
  resize: none;
  background: none;
  border: 0;
  outline: none;
  margin: 0;
  padding: .3125rem 0;
}

.card-comment-box input:focus, .card-comment-box textarea:focus {
  box-shadow: none;
  background: none;
}

.dropdown-menu-fluid {
  width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
  left: var(--x-gutter-x, 1.5rem) !important;
}

.dropdown-menu-fluid[data-bs-popper] {
  left: var(--x-gutter-x, 1.5rem);
}

@media (min-width: 576px) {
  .dropdown-menu-sm-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }

  .dropdown-menu-sm-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }

  .dropdown-menu-md-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }

  .dropdown-menu-lg-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }

  .dropdown-menu-xl-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }

  .dropdown-menu-xxl-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}

@media (min-width: 768px) {
  .dropdown-menu-sm {
    min-width: 10rem !important;
  }

  .dropdown-menu-sm.dropdown-menu-arrow.dropdown-menu-right:before {
    left: auto;
    right: 2rem;
  }

  .dropdown-menu-md {
    min-width: 25rem !important;
  }

  .dropdown-menu-lg {
    min-width: 30rem !important;
  }

  .dropdown-menu-xl {
    min-width: 40rem !important;
  }

  .dropdown-menu-2xl {
    min-width: 50rem !important;
  }

  .dropdown-menu-centered {
    transform: translateX(-50%)translateY(-3px)scale(.96);
    left: 50% !important;
  }
}

.dropdown-item {
  --x-dropdown-item-border-radius: .375rem;
  border-radius: var(--x-dropdown-item-border-radius, 0);
}

.dropdown-body {
  padding-left: var(--x-dropdown-item-padding-x);
  padding-right: var(--x-dropdown-item-padding-x);
}

.dropdown-heading {
  color: #0f172a;
  font-size: 1rem;
  font-weight: 500;
}

.dropdown-helper {
  color: #475569;
}

.dropdown-group {
  display: block;
}

.dropdown-group:hover .dropdown-heading {
  color: #8957ff;
}

.dropdown-secondary {
  background-color: #f1f5f9;
}

.frame-laptop {
  position: relative;
}

.frame-laptop .frame-inner {
  width: 70%;
  height: 78%;
  margin: 2.5% 15% 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.frame-iphone {
  z-index: 100;
  position: relative;
}

.frame-iphone .frame-inner {
  z-index: -1;
  border-radius: 5%;
  width: 92%;
  height: 96%;
  margin: 4% 4% 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.frame-stack {
  position: relative;
}

.frame-stack .frame {
  position: absolute;
}

.frame-stack-iphone-iphone {
  padding-bottom: 130.25%;
}

.frame-stack-iphone-iphone > .frame-iphone:first-child {
  z-index: 1;
  width: 65.526%;
  bottom: 0;
  left: 0;
}

.frame-stack-iphone-iphone > .frame-iphone:last-child {
  width: 72.8324%;
  top: 0;
  right: 0;
}

.frame-stack-iphone-laptop, .frame-stack-laptop-iphone {
  padding-bottom: 62.426%;
}

.frame-stack-iphone-laptop > .frame-laptop, .frame-stack-laptop-iphone > .frame-laptop {
  width: 91.716%;
}

.frame-stack-iphone-laptop > .frame-iphone, .frame-stack-laptop-iphone > .frame-iphone {
  z-index: 1;
  width: 27.9586%;
}

.frame-stack-laptop-iphone > .frame-laptop {
  top: 0;
  left: 0;
}

.frame-stack-iphone-laptop > .frame-laptop {
  top: 0;
  right: 0;
}

.frame-stack-laptop-iphone > .frame-iphone {
  bottom: 0;
  right: 0;
}

.frame-stack-iphone-laptop > .frame-iphone {
  bottom: 0;
  left: 0;
}

.icon {
  --x-size: 3rem;
  font-size: calc(var(--x-size) / 1.75);
  line-height: 1;
}

.icon svg {
  width: 1em;
  height: 1em;
}

.icon-xl {
  --x-size: 5rem;
}

.icon-lg {
  --x-size: 4rem;
}

.icon-sm {
  --x-size: 2rem;
}

.icon-xs {
  --x-size: 1.25rem;
}

.icon-shape {
  text-align: center;
  vertical-align: middle;
  width: var(--x-size);
  height: var(--x-size);
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.list-group-flush {
  --x-list-group-item-padding-x: 0;
}

.list-group-borderless {
  --x-list-group-border-width: 0;
  --x-list-group-item-padding-y: 0;
}

.list-group .form-check-input:checked + .form-checked-content {
  opacity: .5;
}

.list-group .form-check-input-placeholder {
  border-style: dashed;
}

.list-group [contenteditable]:focus {
  outline: 0;
}

.list-group-checkable .list-group-item {
  cursor: pointer;
}

.list-group-item-check {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

.list-group-item-check:hover + .list-group-item {
  color: var(--x-list-group-action-hover-color);
  background-color: var(--x-list-group-action-hover-bg);
  border-color: var(--x-list-group-action-hover-bg);
}

.list-group-item-check:checked + .list-group-item {
  color: var(--x-list-group-action-active-color);
  background-color: var(--x-list-group-action-active-bg);
  border-color: var(--x-list-group-action-active-bg);
}

.list-group-item-check[disabled] + .list-group-item, .list-group-item-check:disabled + .list-group-item {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.list-group-radio {
  --x-list-group-checked-border-color: var(--x-primary);
  --x-list-group-checked-shadow-color: var(--x-primary);
}

.list-group-radio .list-group-item {
  cursor: pointer;
}

.list-group-radio .form-check-input {
  z-index: 2;
  margin-top: -.5em;
}

.list-group-radio .form-check-input:checked + .list-group-item {
  border-color: var(--x-list-group-checked-border-color);
  box-shadow: 0 0 0 1px var(--x-list-group-checked-shadow-color);
}

.list-group-radio .form-check-input[disabled] + .list-group-item, .list-group-radio .form-check-input:disabled + .list-group-item {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

@media (max-width: 767.98px) {
  .modal-drawer {
    --x-modal-border-radius: var(--x-border-radius-lg) var(--x-border-radius-lg) 0 0;
    justify-content: center;
    align-items: end;
    margin: 0;
    transition: transform .15s;
    display: flex;
    transform: translateY(100%);
  }

  .modal-drawer.show {
    transform: translateY(0);
  }

  .modal-drawer .modal-dialog {
    align-items: end;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    margin: 0;
  }
}

.nav-tabs-flush {
  --x-nav-link-padding-x: 0;
  --x-nav-tabs-border-radius: 0;
  --x-nav-tabs-link-active-border-color: #8957ff;
  border: 0;
}

.nav-tabs-flush .nav-item.show .nav-link {
  border-color: rgba(0, 0, 0, 0);
}

.nav-tabs-flush .nav-link {
  border-bottom: var(--x-nav-tabs-border-width) solid transparent;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
}

.navbar {
  --x-navbar-bg: transparent;
  --x-navbar-color: #1e293b;
  --x-navbar-icon-color: #1e293b;
  --x-navbar-hover-bg: #e2e8f0;
  --x-navbar-hover-color: #1e293b;
  --x-navbar-active-bg: #e2e8f0;
  --x-navbar-active-color: #0f172a;
  --x-nav-link-padding-x: 1rem;
  --x-nav-link-padding-y: .375rem;
  --x-nav-link-border-radius: .375rem;
  z-index: 100;
  position: relative;
}

.navbar [class^="container"] {
  position: relative;
}

.navbar-user {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.navbar-img-brand {
  font-size: initial;
}

.navbar-top {
  align-items: center;
  display: flex;
  position: relative;
}

.navbar-top .navbar-nav {
  flex-direction: row;
}

.navbar-top .navbar-nav .nav-item:last-child {
  margin-right: 0;
}

.navbar-top .navbar-nav .nav-link {
  padding: .5rem;
}

.navbar-collapse:before {
  content: "";
  opacity: 0;
  border-top-style: solid;
  border-top-width: 1px;
  margin: .75rem -1rem;
  transition: all .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-collapse:before {
    transition: none;
  }
}

.navbar-collapse.show:before, .navbar-collapse.collapsing:before {
  opacity: 1;
}

.navbar-light .navbar-collapse:before {
  border-top-color: rgba(15, 23, 42, .1);
}

.navbar-light .collapse .nav:before {
  border-color: rgba(15, 23, 42, .1);
}

.navbar-dark {
  --x-navbar-bg: transparent;
  --x-navbar-color: rgba(255, 255, 255, .85);
  --x-navbar-hover-bg: rgba(255, 255, 255, .1);
  --x-navbar-hover-color: #fff;
  --x-navbar-active-bg: rgba(255, 255, 255, .1);
  --x-navbar-active-color: #fff;
}

.navbar-dark .navbar-collapse:before {
  border-top-color: rgba(255, 255, 255, .2);
}

.navbar-dark .collapse .nav:before {
  border-color: rgba(255, 255, 255, .2);
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav {
    --x-nav-link-padding-x: 1rem;
    --x-nav-link-padding-y: .5rem;
  }

  .navbar-expand-sm .navbar-nav > .nav-item > .nav-link, .navbar-expand-sm .navbar-nav > .nav-link {
    background-color: var(--x-navbar-bg);
    color: var(--x-navbar-color);
    padding-top: var(--x-nav-link-padding-y);
    padding-bottom: var(--x-nav-link-padding-y);
    border-radius: var(--x-nav-link-border-radius);
    transition: all .15s linear;
  }
}

@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-sm .navbar-nav > .nav-item > .nav-link, .navbar-expand-sm .navbar-nav > .nav-link {
    transition: none;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav > .nav-item > .nav-link:hover, .navbar-expand-sm .navbar-nav > .nav-item > .nav-link:focus, .navbar-expand-sm .navbar-nav > .nav-link:hover, .navbar-expand-sm .navbar-nav > .nav-link:focus {
    background-color: var(--x-navbar-hover-bg);
    color: var(--x-navbar-hover-color);
  }

  .navbar-expand-sm .navbar-nav > .nav-item > .nav-link.active, .navbar-expand-sm .navbar-nav > .nav-link.active {
    background-color: var(--x-navbar-active-bg);
    color: var(--x-navbar-active-color);
  }

  .navbar-expand-sm .navbar-brand img {
    height: 40px;
  }

  .navbar-expand-sm .navbar-brand.dropdown-toggle:after, .navbar-expand-sm .navbar-collapse .collapse-header, .navbar-expand-sm .navbar-collapse:before {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav {
    --x-nav-link-padding-x: 1rem;
    --x-nav-link-padding-y: .5rem;
  }

  .navbar-expand-md .navbar-nav > .nav-item > .nav-link, .navbar-expand-md .navbar-nav > .nav-link {
    background-color: var(--x-navbar-bg);
    color: var(--x-navbar-color);
    padding-top: var(--x-nav-link-padding-y);
    padding-bottom: var(--x-nav-link-padding-y);
    border-radius: var(--x-nav-link-border-radius);
    transition: all .15s linear;
  }
}

@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-md .navbar-nav > .nav-item > .nav-link, .navbar-expand-md .navbar-nav > .nav-link {
    transition: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav > .nav-item > .nav-link:hover, .navbar-expand-md .navbar-nav > .nav-item > .nav-link:focus, .navbar-expand-md .navbar-nav > .nav-link:hover, .navbar-expand-md .navbar-nav > .nav-link:focus {
    background-color: var(--x-navbar-hover-bg);
    color: var(--x-navbar-hover-color);
  }

  .navbar-expand-md .navbar-nav > .nav-item > .nav-link.active, .navbar-expand-md .navbar-nav > .nav-link.active {
    background-color: var(--x-navbar-active-bg);
    color: var(--x-navbar-active-color);
  }

  .navbar-expand-md .navbar-brand img {
    height: 40px;
  }

  .navbar-expand-md .navbar-brand.dropdown-toggle:after, .navbar-expand-md .navbar-collapse .collapse-header, .navbar-expand-md .navbar-collapse:before {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    --x-nav-link-padding-x: 1rem;
    --x-nav-link-padding-y: .5rem;
  }

  .navbar-expand-lg .navbar-nav > .nav-item > .nav-link, .navbar-expand-lg .navbar-nav > .nav-link {
    background-color: var(--x-navbar-bg);
    color: var(--x-navbar-color);
    padding-top: var(--x-nav-link-padding-y);
    padding-bottom: var(--x-nav-link-padding-y);
    border-radius: var(--x-nav-link-border-radius);
    transition: all .15s linear;
  }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-lg .navbar-nav > .nav-item > .nav-link, .navbar-expand-lg .navbar-nav > .nav-link {
    transition: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav > .nav-item > .nav-link:hover, .navbar-expand-lg .navbar-nav > .nav-item > .nav-link:focus, .navbar-expand-lg .navbar-nav > .nav-link:hover, .navbar-expand-lg .navbar-nav > .nav-link:focus {
    background-color: var(--x-navbar-hover-bg);
    color: var(--x-navbar-hover-color);
  }

  .navbar-expand-lg .navbar-nav > .nav-item > .nav-link.active, .navbar-expand-lg .navbar-nav > .nav-link.active {
    background-color: var(--x-navbar-active-bg);
    color: var(--x-navbar-active-color);
  }

  .navbar-expand-lg .navbar-brand img {
    height: 40px;
  }

  .navbar-expand-lg .navbar-brand.dropdown-toggle:after, .navbar-expand-lg .navbar-collapse .collapse-header, .navbar-expand-lg .navbar-collapse:before {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav {
    --x-nav-link-padding-x: 1rem;
    --x-nav-link-padding-y: .5rem;
  }

  .navbar-expand-xl .navbar-nav > .nav-item > .nav-link, .navbar-expand-xl .navbar-nav > .nav-link {
    background-color: var(--x-navbar-bg);
    color: var(--x-navbar-color);
    padding-top: var(--x-nav-link-padding-y);
    padding-bottom: var(--x-nav-link-padding-y);
    border-radius: var(--x-nav-link-border-radius);
    transition: all .15s linear;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-xl .navbar-nav > .nav-item > .nav-link, .navbar-expand-xl .navbar-nav > .nav-link {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav > .nav-item > .nav-link:hover, .navbar-expand-xl .navbar-nav > .nav-item > .nav-link:focus, .navbar-expand-xl .navbar-nav > .nav-link:hover, .navbar-expand-xl .navbar-nav > .nav-link:focus {
    background-color: var(--x-navbar-hover-bg);
    color: var(--x-navbar-hover-color);
  }

  .navbar-expand-xl .navbar-nav > .nav-item > .nav-link.active, .navbar-expand-xl .navbar-nav > .nav-link.active {
    background-color: var(--x-navbar-active-bg);
    color: var(--x-navbar-active-color);
  }

  .navbar-expand-xl .navbar-brand img {
    height: 40px;
  }

  .navbar-expand-xl .navbar-brand.dropdown-toggle:after, .navbar-expand-xl .navbar-collapse .collapse-header, .navbar-expand-xl .navbar-collapse:before {
    display: none;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-nav {
    --x-nav-link-padding-x: 1rem;
    --x-nav-link-padding-y: .5rem;
  }

  .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link, .navbar-expand-xxl .navbar-nav > .nav-link {
    background-color: var(--x-navbar-bg);
    color: var(--x-navbar-color);
    padding-top: var(--x-nav-link-padding-y);
    padding-bottom: var(--x-nav-link-padding-y);
    border-radius: var(--x-nav-link-border-radius);
    transition: all .15s linear;
  }
}

@media (min-width: 1400px) and (prefers-reduced-motion: reduce) {
  .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link, .navbar-expand-xxl .navbar-nav > .nav-link {
    transition: none;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link:hover, .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link:focus, .navbar-expand-xxl .navbar-nav > .nav-link:hover, .navbar-expand-xxl .navbar-nav > .nav-link:focus {
    background-color: var(--x-navbar-hover-bg);
    color: var(--x-navbar-hover-color);
  }

  .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link.active, .navbar-expand-xxl .navbar-nav > .nav-link.active {
    background-color: var(--x-navbar-active-bg);
    color: var(--x-navbar-active-color);
  }

  .navbar-expand-xxl .navbar-brand img {
    height: 40px;
  }

  .navbar-expand-xxl .navbar-brand.dropdown-toggle:after, .navbar-expand-xxl .navbar-collapse .collapse-header, .navbar-expand-xxl .navbar-collapse:before {
    display: none;
  }
}

.navbar-expand .navbar-nav {
  --x-nav-link-padding-x: 1rem;
  --x-nav-link-padding-y: .5rem;
}

.navbar-expand .navbar-nav > .nav-item > .nav-link, .navbar-expand .navbar-nav > .nav-link {
  background-color: var(--x-navbar-bg);
  color: var(--x-navbar-color);
  padding-top: var(--x-nav-link-padding-y);
  padding-bottom: var(--x-nav-link-padding-y);
  border-radius: var(--x-nav-link-border-radius);
  transition: all .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-expand .navbar-nav > .nav-item > .nav-link, .navbar-expand .navbar-nav > .nav-link {
    transition: none;
  }
}

.navbar-expand .navbar-nav > .nav-item > .nav-link:hover, .navbar-expand .navbar-nav > .nav-item > .nav-link:focus, .navbar-expand .navbar-nav > .nav-link:hover, .navbar-expand .navbar-nav > .nav-link:focus {
  background-color: var(--x-navbar-hover-bg);
  color: var(--x-navbar-hover-color);
}

.navbar-expand .navbar-nav > .nav-item > .nav-link.active, .navbar-expand .navbar-nav > .nav-link.active {
  background-color: var(--x-navbar-active-bg);
  color: var(--x-navbar-active-color);
}

.navbar-expand .navbar-brand img {
  height: 40px;
}

.navbar-expand .navbar-brand.dropdown-toggle:after, .navbar-expand .navbar-collapse .collapse-header, .navbar-expand .navbar-collapse:before {
  display: none;
}

.navbar-toggler {
  border-radius: var(--x-border-radius);
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  padding: .25rem .375rem;
  font-size: 1.25rem;
  line-height: 1;
  transition: box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  outline: 0;
  text-decoration: none;
  box-shadow: 0 0 0 2px;
}

.navbar-light .navbar-toggler {
  color: #475569;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.navbar-light .navbar-toggler:focus {
  color: rgba(0, 0, 0, 0);
  background-color: #f1f5f9;
}

.navbar-dark .navbar-toggler {
  color: #e2e8f0;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.navbar-dark .navbar-toggler:focus {
  color: rgba(0, 0, 0, 0);
  background-color: #f1f5f9;
}

.navbar-toggler-icon {
  width: 1.25em;
  height: 1.25em;
}

.navbar-vertical {
  --x-nav-link-font-size: .875rem;
}

.navbar-vertical .navbar-nav {
  --x-nav-link-padding-x: 1rem;
  --x-nav-link-padding-y: .5rem;
}

.navbar-vertical .navbar-nav .nav-link {
  white-space: nowrap;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar-vertical .navbar-nav .nav-link i, .navbar-vertical .navbar-nav .nav-link svg {
  min-width: 1.875rem;
}

.navbar-vertical .navbar-nav .nav-link .dropdown-menu {
  border: none;
}

.navbar-vertical .navbar-nav .nav-link .dropdown-menu .dropdown-menu {
  margin-left: .5rem;
}

.navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
  content: "";
  background-repeat: no-repeat;
  background-size: .875rem .875rem;
  width: .875rem;
  height: .875rem;
  margin-left: auto;
  transition: all .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
    transition: none;
  }
}

.navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"][aria-expanded="true"]:after {
  color: #8957ff;
  transform: rotate(90deg);
}

.navbar-vertical .navbar-nav > .nav-item {
  margin-top: 2px;
}

.navbar-vertical .navbar-nav .nav-sm .nav-link {
  font-size: .875rem;
}

.navbar-vertical .navbar-nav .nav .nav-link {
  padding-top: calc(var(--x-nav-link-padding-y) / 1.75);
  padding-bottom: calc(var(--x-nav-link-padding-y) / 1.75);
  padding-left: calc(var(--x-nav-link-padding-x)  + 1.875rem);
  font-weight: 400;
}

.navbar-vertical .navbar-nav .nav .nav .nav-link {
  padding-left: 2.8125rem;
}

.navbar-vertical.navbar-light .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 16' fill='%231e293b' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.navbar-vertical.navbar-light .nav-link-text, .navbar-vertical.navbar-light .navbar-heading {
  color: var(--x-navbar-color);
}

.navbar-vertical.navbar-dark .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 16' fill='rgba(255, 255, 255, 0.85)' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.navbar-vertical.navbar-dark .nav-link-text, .navbar-vertical.navbar-dark .navbar-heading {
  color: rgba(255, 255, 255, .55);
}

@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm {
    z-index: 1000;
    border-radius: 0;
    width: 100%;
    max-width: 280px;
    display: block;
    overflow-y: auto;
    box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
  }

  .navbar-vertical.navbar-expand-sm + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-sm .nav-link-text, .navbar-vertical.navbar-expand-sm .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all .15s ease-in-out;
  }
}

@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-sm .nav-link-text, .navbar-vertical.navbar-expand-sm .navbar-heading {
    transition: none;
  }
}

@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm.show {
    border-radius: 0;
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-sm.show.navbar-lg {
    max-width: 320px;
  }

  .navbar-vertical.navbar-expand-sm.show.navbar-xl {
    max-width: 370px;
  }

  .navbar-vertical.navbar-expand-sm.show .nav-link-text, .navbar-vertical.navbar-expand-sm.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-vertical.navbar-expand-sm.show + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-sm.hide {
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-sm.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-sm.hide + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-sm .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .navbar-vertical.navbar-expand-sm > [class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-vertical.navbar-expand-sm .navbar-collapse {
    opacity: 1;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    display: flex;
  }

  .navbar-vertical.navbar-expand-sm .navbar-nav {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md {
    z-index: 1000;
    border-radius: 0;
    width: 100%;
    max-width: 280px;
    display: block;
    overflow-y: auto;
    box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
  }

  .navbar-vertical.navbar-expand-md + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-md .nav-link-text, .navbar-vertical.navbar-expand-md .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all .15s ease-in-out;
  }
}

@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-md .nav-link-text, .navbar-vertical.navbar-expand-md .navbar-heading {
    transition: none;
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md.show {
    border-radius: 0;
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-md.show.navbar-lg {
    max-width: 320px;
  }

  .navbar-vertical.navbar-expand-md.show.navbar-xl {
    max-width: 370px;
  }

  .navbar-vertical.navbar-expand-md.show .nav-link-text, .navbar-vertical.navbar-expand-md.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-vertical.navbar-expand-md.show + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-md.hide {
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-md.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-md.hide + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-md .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .navbar-vertical.navbar-expand-md > [class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse {
    opacity: 1;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    display: flex;
  }

  .navbar-vertical.navbar-expand-md .navbar-nav {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg {
    z-index: 1000;
    border-radius: 0;
    width: 100%;
    max-width: 280px;
    display: block;
    overflow-y: auto;
    box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
  }

  .navbar-vertical.navbar-expand-lg + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-lg .nav-link-text, .navbar-vertical.navbar-expand-lg .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all .15s ease-in-out;
  }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-lg .nav-link-text, .navbar-vertical.navbar-expand-lg .navbar-heading {
    transition: none;
  }
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg.show {
    border-radius: 0;
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-lg.show.navbar-lg {
    max-width: 320px;
  }

  .navbar-vertical.navbar-expand-lg.show.navbar-xl {
    max-width: 370px;
  }

  .navbar-vertical.navbar-expand-lg.show .nav-link-text, .navbar-vertical.navbar-expand-lg.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-vertical.navbar-expand-lg.show + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-lg.hide {
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-lg.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-lg.hide + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-lg .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .navbar-vertical.navbar-expand-lg > [class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-vertical.navbar-expand-lg .navbar-collapse {
    opacity: 1;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    display: flex;
  }

  .navbar-vertical.navbar-expand-lg .navbar-nav {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl {
    z-index: 1000;
    border-radius: 0;
    width: 100%;
    max-width: 280px;
    display: block;
    overflow-y: auto;
    box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
  }

  .navbar-vertical.navbar-expand-xl + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-xl .nav-link-text, .navbar-vertical.navbar-expand-xl .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all .15s ease-in-out;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-xl .nav-link-text, .navbar-vertical.navbar-expand-xl .navbar-heading {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl.show {
    border-radius: 0;
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-xl.show.navbar-lg {
    max-width: 320px;
  }

  .navbar-vertical.navbar-expand-xl.show.navbar-xl {
    max-width: 370px;
  }

  .navbar-vertical.navbar-expand-xl.show .nav-link-text, .navbar-vertical.navbar-expand-xl.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-vertical.navbar-expand-xl.show + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-xl.hide {
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-xl.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-xl.hide + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-xl .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .navbar-vertical.navbar-expand-xl > [class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-vertical.navbar-expand-xl .navbar-collapse {
    opacity: 1;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    display: flex;
  }

  .navbar-vertical.navbar-expand-xl .navbar-nav {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl {
    z-index: 1000;
    border-radius: 0;
    width: 100%;
    max-width: 280px;
    display: block;
    overflow-y: auto;
    box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
  }

  .navbar-vertical.navbar-expand-xxl + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-xxl .nav-link-text, .navbar-vertical.navbar-expand-xxl .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all .15s ease-in-out;
  }
}

@media (min-width: 1400px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-xxl .nav-link-text, .navbar-vertical.navbar-expand-xxl .navbar-heading {
    transition: none;
  }
}

@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl.show {
    border-radius: 0;
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-xxl.show.navbar-lg {
    max-width: 320px;
  }

  .navbar-vertical.navbar-expand-xxl.show.navbar-xl {
    max-width: 370px;
  }

  .navbar-vertical.navbar-expand-xxl.show .nav-link-text, .navbar-vertical.navbar-expand-xxl.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-vertical.navbar-expand-xxl.show + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-xxl.hide {
    max-width: 280px;
  }

  .navbar-vertical.navbar-expand-xxl.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-xxl.hide + .main-content {
    margin-left: 280px;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .navbar-vertical.navbar-expand-xxl > [class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-collapse {
    opacity: 1;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    display: flex;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-nav {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}

.navbar-vertical.navbar-expand {
  z-index: 1000;
  border-radius: 0;
  width: 100%;
  max-width: 280px;
  display: block;
  overflow-y: auto;
  box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
}

.navbar-vertical.navbar-expand + .main-content {
  margin-left: 280px;
}

.navbar-vertical.navbar-expand .nav-link-text, .navbar-vertical.navbar-expand .navbar-heading {
  opacity: 0;
  pointer-events: none;
  transition: all .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand .nav-link-text, .navbar-vertical.navbar-expand .navbar-heading {
    transition: none;
  }
}

.navbar-vertical.navbar-expand.show {
  border-radius: 0;
  max-width: 280px;
}

.navbar-vertical.navbar-expand.show.navbar-lg {
  max-width: 320px;
}

.navbar-vertical.navbar-expand.show.navbar-xl {
  max-width: 370px;
}

.navbar-vertical.navbar-expand.show .nav-link-text, .navbar-vertical.navbar-expand.show .navbar-heading {
  opacity: 1;
  pointer-events: auto;
}

.navbar-vertical.navbar-expand.show + .main-content {
  margin-left: 280px;
}

.navbar-vertical.navbar-expand.hide {
  max-width: 280px;
}

.navbar-vertical.navbar-expand.hide .nav-link-text {
  opacity: 0;
  pointer-events: none;
}

.navbar-vertical.navbar-expand.hide + .main-content {
  margin-left: 280px;
}

.navbar-vertical.navbar-expand .navbar-brand {
  margin-right: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.navbar-vertical.navbar-expand > [class*="container"] {
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  padding-left: 0;
  padding-right: 0;
}

.navbar-vertical.navbar-expand .navbar-collapse {
  opacity: 1;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  display: flex;
}

.navbar-vertical.navbar-expand .navbar-nav {
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
}

.pagination {
  margin: 0;
}

.pagination-spaced {
  border-radius: 0;
}

.pagination-spaced .page-link {
  border-radius: var(--x-border-radius);
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0;
  display: inline-flex;
}

.pagination-spaced.pagination-sm .page-link {
  border-radius: .25rem;
  width: 1.875rem;
  height: 1.875rem;
}

.pagination-spaced.pagination-lg .page-link {
  width: 3.25rem;
  height: 3.25rem;
}

@keyframes progressCircleGrow {
  0%, 33% {
    --x-progress-circle-percentage: 0;
  }

  100% {
    --x-progress-circle-percentage: var(--value);
  }
}

@property --x-progress-circle-percentage {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

.progress-circle {
  --x-progress-circle-size: 2.75rem;
  --x-progress-circle-bg: var(--x-secondary-bg);
  --x-progress-circle-color: #8957ff;
  --x-progress-circle-bar-bg: #8957ff;
  --x-progress-circle-percentage: var(--value);
  width: var(--x-progress-circle-size);
  height: var(--x-progress-circle-size);
  background: radial-gradient(closest-side, currentColor 80%, transparent 0 99.9%, currentColor 0), conic-gradient(var(--x-progress-circle-bar-bg) calc(var(--x-progress-circle-percentage) * 1%), var(--x-progress-circle-bg) 0);
  font-size: calc(var(--x-progress-circle-size) / 5);
  border-radius: 50%;
  place-items: center;
  animation: 3s forwards progressCircleGrow;
  display: grid;
}

.progress-circle-counter:before {
  counter-reset: percentage var(--value);
  content: counter(percentage) "%";
  color: var(--x-progress-circle-color);
}

.progress-circle-xl {
  --x-progress-circle-size: 4rem;
}

.progress-circle-lg {
  --x-progress-circle-size: 3.25rem;
}

.progress-circle-sm {
  --x-progress-circle-size: 2.29688rem;
}

.progress-circle-primary {
  --x-progress-circle-color: var(--x-primary);
  --x-progress-circle-bar-bg: var(--x-primary);
}

.progress-circle-secondary {
  --x-progress-circle-color: var(--x-secondary);
  --x-progress-circle-bar-bg: var(--x-secondary);
}

.progress-circle-success {
  --x-progress-circle-color: var(--x-success);
  --x-progress-circle-bar-bg: var(--x-success);
}

.progress-circle-info {
  --x-progress-circle-color: var(--x-info);
  --x-progress-circle-bar-bg: var(--x-info);
}

.progress-circle-warning {
  --x-progress-circle-color: var(--x-warning);
  --x-progress-circle-bar-bg: var(--x-warning);
}

.progress-circle-danger {
  --x-progress-circle-color: var(--x-danger);
  --x-progress-circle-bar-bg: var(--x-danger);
}

.progress-circle-light {
  --x-progress-circle-color: var(--x-light);
  --x-progress-circle-bar-bg: var(--x-light);
}

.progress-circle-dark {
  --x-progress-circle-color: var(--x-dark);
  --x-progress-circle-bar-bg: var(--x-dark);
}

.table {
  --x-table-cell-font-size: .8125rem;
  --x-table-th-bg: transparent;
  --x-table-th-color: #334155;
  --x-table-th-font-size: .675rem;
  --x-table-th-font-weight: 500;
  --x-table-th-letter-spacing: .025em;
  --x-table-th-text-transform: uppercase;
  margin-bottom: 0;
}

.table thead th {
  font-size: var(--x-table-th-font-size);
  font-weight: var(--x-table-th-font-weight);
  text-transform: var(--x-table-th-text-transform);
  letter-spacing: var(--x-table-th-letter-spacing);
  border-bottom-width: var(--x-border-width);
  white-space: nowrap;
  vertical-align: middle;
}

.table tbody th {
  font-size: var(--x-table-cell-font-size);
}

.table td {
  font-size: var(--x-table-cell-font-size);
  white-space: nowrap;
}

.table td .progress {
  width: 120px;
  height: 3px;
  margin: 0;
}

.table-flush th, .table-flush td {
  border-left: 0;
  border-right: 0;
}

.table-flush thead > tr th {
  border-top: 0;
}

.table-flush thead > tr th:first-child {
  padding-left: 0;
}

.table-flush thead > tr th:last-child {
  padding-right: 0;
}

.table-flush tbody > tr:last-child th, .table-flush tbody > tr:last-child td {
  border-bottom: 0;
}

.table-flush tbody > tr th:first-child, .table-flush tbody > tr td:first-child {
  padding-left: 0;
}

.table-flush tbody > tr th:last-child, .table-flush tbody > tr td:last-child {
  padding-right: 0;
}

.table-spaced {
  border-collapse: separate;
  border-spacing: 0 1rem;
}

.table-spaced thead th {
  border: 0;
}

.table-spaced thead tr th, .table-spaced thead tr td, .table-spaced tbody tr th, .table-spaced tbody tr td {
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  border-top: var(--x-border-width) solid var(--x-border-color) !important;
  border-bottom: var(--x-border-width) solid var(--x-border-color) !important;
}

.table-spaced thead tr th:first-child, .table-spaced thead tr td:first-child, .table-spaced tbody tr th:first-child, .table-spaced tbody tr td:first-child {
  border-radius: var(--x-border-radius) 0 0 var(--x-border-radius);
  border-left: var(--x-border-width) solid var(--x-border-color) !important;
}

.table-spaced thead tr th:first-child:after, .table-spaced thead tr td:first-child:after, .table-spaced tbody tr th:first-child:after, .table-spaced tbody tr td:first-child:after {
  border-left: var(--x-border-width) solid var(--x-border-color);
  border-radius: var(--x-border-radius) 0 0 var(--x-border-radius);
}

.table-spaced thead tr th:last-child, .table-spaced thead tr td:last-child, .table-spaced tbody tr th:last-child, .table-spaced tbody tr td:last-child {
  border-radius: 0 var(--x-border-radius) var(--x-border-radius) 0;
  border-right: var(--x-border-width) solid var(--x-border-color) !important;
}

.table-spaced tbody tr {
  border-radius: var(--x-border-radius);
}

.form-label {
  margin-bottom: .5rem;
  font-size: .875rem;
  font-weight: 500;
}

.col-form-label {
  padding-top: calc(.5rem + var(--x-border-width));
  padding-bottom: calc(.5rem + var(--x-border-width));
  font-size: inherit;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 1.625;
}

.col-form-label-lg {
  padding-top: calc(.75rem + var(--x-border-width));
  padding-bottom: calc(.75rem + var(--x-border-width));
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(.375rem + var(--x-border-width));
  padding-bottom: calc(.375rem + var(--x-border-width));
  font-size: .875rem;
}

.form-text {
  color: var(--x-secondary-color);
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  color: var(--x-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--x-body-bg);
  border: var(--x-border-width) solid var(--x-border-color);
  border-radius: var(--x-border-radius);
  background-clip: padding-box;
  width: 100%;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: 0 1px 2px rgba(50, 50, 71, .08);
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--x-body-color);
  background-color: var(--x-body-bg);
  border-color: var(--x-primary);
  box-shadow: 0px 1px 2px rgba(50, 50, 71, .08), 0 0 0 2px rgba(var(--x-primary-rgb), .25);
  outline: 0;
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.625em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  padding: 0;
  display: block;
}

.form-control::placeholder {
  color: var(--x-secondary-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--x-secondary-bg);
  opacity: 1;
}

.form-control::-webkit-file-upload-button {
  margin: -.5rem -1rem;
  color: var(--x-body-color);
  background-color: var(--x-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-radius: 0;
  padding: .5rem 1rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control::file-selector-button {
  margin: -.5rem -1rem;
  color: var(--x-body-color);
  background-color: var(--x-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-radius: 0;
  padding: .5rem 1rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::-webkit-file-upload-button {
  border-right-width: var(--x-border-width);
  margin-right: 1rem;
}

.form-control:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::file-selector-button {
  border-right-width: var(--x-border-width);
  margin-right: 1rem;
}

.form-control:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  border-left-width: var(--x-border-width);
  margin-left: 1rem;
}

.form-control:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  border-left-width: var(--x-border-width);
  margin-left: 1rem;
}

.form-control:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  border-left-width: var(--x-border-width);
  margin-left: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }

  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: var(--x-secondary-bg);
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--x-secondary-bg);
}

.form-control-plaintext {
  color: var(--x-body-color);
  border: solid rgba(0, 0, 0, 0);
  border-width: var(--x-border-width) 0;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  margin-bottom: 0;
  padding: .5rem 0;
  line-height: 1.625;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.625em + .75rem + calc(var(--x-border-width) * 2));
  border-radius: var(--x-border-radius-sm);
  padding: .375rem .875rem;
  font-size: .875rem;
}

.form-control-sm::-webkit-file-upload-button {
  margin: -.375rem -.875rem;
  padding: .375rem .875rem;
}

.form-control-sm::file-selector-button {
  margin: -.375rem -.875rem;
  padding: .375rem .875rem;
}

.form-control-sm:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::-webkit-file-upload-button {
  margin-right: .875rem;
}

.form-control-sm:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::file-selector-button {
  margin-right: .875rem;
}

.form-control-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: .875rem;
}

.form-control-sm:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: .875rem;
}

.form-control-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: .875rem;
}

.form-control-lg {
  min-height: calc(1.625em + 1.5rem + calc(var(--x-border-width) * 2));
  border-radius: var(--x-border-radius-lg);
  padding: .75rem 1.25rem;
  font-size: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  margin: -.75rem -1.25rem;
  padding: .75rem 1.25rem;
}

.form-control-lg::file-selector-button {
  margin: -.75rem -1.25rem;
  padding: .75rem 1.25rem;
}

.form-control-lg:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::-webkit-file-upload-button {
  margin-right: 1.25rem;
}

.form-control-lg:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::file-selector-button {
  margin-right: 1.25rem;
}

.form-control-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: 1.25rem;
}

.form-control-lg:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1.25rem;
}

.form-control-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1.25rem;
}

textarea.form-control {
  min-height: calc(1.625em + 1rem + calc(var(--x-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.625em + .75rem + calc(var(--x-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.625em + 1.5rem + calc(var(--x-border-width) * 2));
}

.form-control-color {
  height: calc(1.625em + 1rem + calc(var(--x-border-width) * 2));
  width: 3rem;
  padding: .5rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: var(--x-border-radius);
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: var(--x-border-radius);
  border: 0 !important;
}

.form-control-color.form-control-sm {
  height: calc(1.625em + .75rem + calc(var(--x-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.625em + 1.5rem + calc(var(--x-border-width) * 2));
}

.form-select {
  --x-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%231e293b' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  color: var(--x-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--x-body-bg);
  background-image: var(--x-form-select-bg-img), var(--x-form-select-bg-icon, none);
  border: var(--x-border-width) solid var(--x-border-color);
  border-radius: var(--x-border-radius);
  box-shadow: var(--x-box-shadow-inset);
  background-position: right 1rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  width: 100%;
  padding: .5rem 3rem .5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: var(--x-primary);
  box-shadow: var(--x-box-shadow-inset), 0 0 0 2px rgba(137, 87, 255, .25);
  outline: 0;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: 1rem;
}

.form-select:disabled {
  background-color: var(--x-secondary-bg);
}

.form-select:-moz-focusring {
  color: rgba(0, 0, 0, 0);
  text-shadow: 0 0 0 var(--x-body-color);
}

.form-select-sm {
  border-radius: var(--x-border-radius-sm);
  padding-top: .375rem;
  padding-bottom: .375rem;
  padding-left: .875rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: var(--x-border-radius-lg);
  padding-top: .75rem;
  padding-bottom: .75rem;
  padding-left: 1.25rem;
  font-size: 1rem;
}

[data-bs-theme="dark"] .form-select {
  --x-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23cbd5e1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  min-height: 1.625rem;
  margin-bottom: 0;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.form-check-input {
  --x-form-check-bg: var(--x-body-bg);
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--x-form-check-bg);
  background-image: var(--x-form-check-bg-image);
  border: var(--x-border-width) solid var(--x-border-color);
  print-color-adjust: exact;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: .3125em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: var(--x-primary);
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(137, 87, 255, .25);
}

.form-check-input:checked {
  background-color: #8957ff;
  border-color: #8957ff;
}

.form-check-input:checked[type="checkbox"] {
  --x-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --x-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFF'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  --x-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  background-color: #8957ff;
  border-color: #8957ff;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 3.375em;
}

.form-switch .form-check-input {
  --x-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-image: var(--x-form-switch-bg);
  background-position: 0;
  border-radius: 2.875em;
  width: 2.875em;
  margin-left: -3.375em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --x-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='var%28--x-primary%29'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  --x-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 3.375em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -3.375em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --x-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: calc(1rem + 4px);
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(var(--x-primary-rgb), .25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(var(--x-primary-rgb), .25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #8957ff;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: 0 .1rem .25rem rgba(0, 0, 0, .1);
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #dccdff;
}

.form-range::-webkit-slider-runnable-track {
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-color: var(--x-secondary-bg);
  box-shadow: var(--x-box-shadow-inset);
  border-color: rgba(0, 0, 0, 0);
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #8957ff;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: 0 .1rem .25rem rgba(0, 0, 0, .1);
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #dccdff;
}

.form-range::-moz-range-track {
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-color: var(--x-secondary-bg);
  box-shadow: var(--x-box-shadow-inset);
  border-color: rgba(0, 0, 0, 0);
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--x-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--x-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + calc(var(--x-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--x-border-width) * 2));
  line-height: 1.25;
}

.form-floating > label {
  z-index: 2;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--x-border-width) solid transparent;
  transform-origin: 0 0;
  height: 100%;
  padding: 1rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem;
}

.form-floating > .form-control::placeholder, .form-floating > .form-control-plaintext::placeholder {
  color: rgba(0, 0, 0, 0);
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
  color: rgba(var(--x-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control:focus ~ label:after, .form-floating > .form-control:not(:placeholder-shown) ~ label:after, .form-floating > .form-control-plaintext ~ label:after, .form-floating > .form-select ~ label:after {
  z-index: -1;
  content: "";
  background-color: var(--x-body-bg);
  border-radius: var(--x-border-radius);
  height: 1.5em;
  position: absolute;
  top: 1rem;
  bottom: 1rem;
  left: .5rem;
  right: .5rem;
}

.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--x-body-color-rgb), .65);
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: var(--x-border-width) 0;
}

.form-floating > :disabled ~ label, .form-floating > .form-control:disabled ~ label {
  color: #475569;
}

.form-floating > :disabled ~ label:after, .form-floating > .form-control:disabled ~ label:after {
  background-color: var(--x-secondary-bg);
}

.input-group {
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  flex: auto;
  width: 1%;
  min-width: 0;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: var(--x-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--x-tertiary-bg);
  border: var(--x-border-width) solid var(--x-border-color);
  border-radius: var(--x-border-radius);
  align-items: center;
  padding: .5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: var(--x-border-radius-lg);
  padding: .75rem 1.25rem;
  font-size: 1rem;
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: var(--x-border-radius-sm);
  padding: .375rem .875rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 4rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--x-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  color: var(--x-form-valid-color);
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: var(--x-success);
  border-radius: var(--x-border-radius);
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--x-form-valid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300CC88' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.40625em + .25rem) center;
  background-repeat: no-repeat;
  background-size: calc(.8125em + .5rem) calc(.8125em + .5rem);
  padding-right: calc(1.625em + 1rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--x-form-valid-border-color);
  box-shadow: 0 0 0 2px rgba(var(--x-success-rgb), .25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.40625em + .25rem) top calc(.40625em + .25rem);
  padding-right: calc(1.625em + 1rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--x-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --x-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300CC88' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1rem center, right 3rem center;
  background-size: 16px 12px, calc(.8125em + .5rem) calc(.8125em + .5rem);
  padding-right: 5.5rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--x-form-valid-border-color);
  box-shadow: 0 0 0 2px rgba(var(--x-success-rgb), .25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(1.625em + 4rem);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--x-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--x-form-valid-color);
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 2px rgba(var(--x-success-rgb), .25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--x-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated .input-group > .form-select:not(:focus):valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  color: var(--x-form-invalid-color);
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: var(--x-danger);
  border-radius: var(--x-border-radius);
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--x-form-invalid-border-color);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FF3366'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF3366' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.40625em + .25rem) center;
  background-repeat: no-repeat;
  background-size: calc(.8125em + .5rem) calc(.8125em + .5rem);
  padding-right: calc(1.625em + 1rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--x-form-invalid-border-color);
  box-shadow: 0 0 0 2px rgba(var(--x-danger-rgb), .25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.40625em + .25rem) top calc(.40625em + .25rem);
  padding-right: calc(1.625em + 1rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--x-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --x-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FF3366'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF3366' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1rem center, right 3rem center;
  background-size: 16px 12px, calc(.8125em + .5rem) calc(.8125em + .5rem);
  padding-right: 5.5rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--x-form-invalid-border-color);
  box-shadow: 0 0 0 2px rgba(var(--x-danger-rgb), .25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(1.625em + 4rem);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--x-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--x-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 2px rgba(var(--x-danger-rgb), .25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--x-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.form-check-label {
  font-size: .875rem;
  font-weight: 500;
}

form {
  margin-bottom: 0;
}

.form-control-muted {
  background-color: #ebeff5;
  border-color: #ebeff5;
}

.form-control-muted:focus {
  background-color: #eef1f7;
}

.form-control-alt {
  background-color: var(--x-body-bg);
  border-color: var(--x-body-bg);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 2px 4px rgba(0, 0, 0, .1);
}

.form-control-alt:focus {
  background-color: var(--x-body-bg);
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 2px #8957ff;
}

.form-control-flush {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  padding: 0;
}

.form-control-flush:focus {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
}

textarea[resize="none"] {
  resize: none !important;
}

textarea[resize="both"] {
  resize: both !important;
}

textarea[resize="vertical"] {
  resize: vertical !important;
}

textarea[resize="horizontal"] {
  resize: horizontal !important;
}

.textarea-autosize {
  display: grid;
}

.textarea-autosize:after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}

.textarea-autosize > textarea {
  resize: none;
  overflow: hidden;
}

.textarea-autosize > textarea, .textarea-autosize:after {
  font: inherit;
  border: 1px solid #000;
  grid-area: 1 / 1 / 2 / 2;
  padding: .5rem;
}

.form-stacked .form-control {
  box-sizing: border-box;
  position: relative;
}

.form-stacked .form-control:first-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: -1px;
}

.form-stacked .form-control:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
  margin-bottom: -1px;
}

.form-stacked .form-control:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-stacked .form-control:focus {
  z-index: 2;
}

.input-group {
  border-radius: var(--x-border-radius);
  box-shadow: 0 1px 2px rgba(50, 50, 71, .08);
}

.input-group .form-control {
  box-shadow: none;
}

.input-group:focus-within {
  box-shadow: 0px 1px 2px rgba(50, 50, 71, .08), 0 0 0 2px rgba(var(--x-primary-rgb), .25);
}

.input-group:focus-within .input-group-text {
  border-color: var(--x-primary);
}

.input-group:focus-within .form-control {
  box-shadow: none;
  border-color: var(--x-primary);
}

.input-group-text {
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.input-group-flush .form-control, .input-group-flush .input-group-text {
  border: 0 !important;
}

.input-group-inline {
  border-radius: var(--x-border-radius);
  position: relative;
}

.input-group-inline.input-group-sm {
  border-radius: var(--x-border-radius-sm);
}

.input-group-inline.input-group-lg {
  border-radius: var(--x-border-radius-lg);
}

.input-group-inline .input-group-text {
  background: var(--x-body-bg);
}

.input-group-inline > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-left: 0;
  padding-left: .25rem;
  margin-left: 0 !important;
}

.input-group-inline > :not(:last-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-right: 0;
  padding-right: .25rem;
  margin-right: 0 !important;
}

.form-switch {
  min-height: 1.5rem;
}

.form-switch > .form-check-input {
  height: 1.5rem;
  margin-top: 0;
}

.form-switch > .form-check-label {
  margin-top: calc(.75rem - .8125em);
}

.form-check-linethrough .form-check-input:checked + .form-check-label {
  text-decoration: line-through;
}

.form-item-checkable {
  margin: 2px;
}

.form-item-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  display: none;
  position: absolute;
}

.form-item-check:checked + .form-item .form-item-click {
  border-color: #8957ff;
  box-shadow: 0 0 0 1px #8957ff;
}

.form-item-check[disabled] + .form-item .form-item-click, .form-item-check:disabled + .form-item .form-item-click {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-color {
  padding: 0;
  list-style: none;
}

.form-color:after, .form-color:before {
  content: "";
  display: table;
}

.form-color:after {
  clear: both;
}

.form-color label {
  float: left;
  opacity: .7;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  margin-bottom: 0;
  margin-right: .375rem;
  padding: .375rem;
  transition: all .15s ease-in-out;
  display: flex;
  transform: scale(.95);
}

@media (prefers-reduced-motion: reduce) {
  .form-color label {
    transition: none;
  }
}

.form-color label:hover {
  cursor: pointer;
  opacity: 1;
}

.form-color label i, .form-color label svg {
  opacity: 0;
  color: rgba(255, 255, 255, .9);
}

.form-color input {
  position: absolute;
  left: -9999px;
}

.form-color input:checked + label {
  opacity: 1;
  transform: scale(1.1);
}

.form-color input:checked + label i, .form-color input:checked + label svg {
  opacity: 1;
}

.form-color-wide label {
  height: 1.5rem;
}

.form-color-xl label {
  width: 5rem;
  height: 5rem;
}

.form-color-xl.form-color-wide label {
  width: 5rem;
  height: 3.5rem;
}

.form-color-lg label {
  width: 3.25rem;
  height: 3.25rem;
}

.form-color-sm label {
  width: 1.25rem;
  height: 1.25rem;
}

.form-dark .form-control {
  color: rgba(255, 255, 255, .9);
  border: var(--x-border-width) solid #272e3f;
  background-color: #272e3f;
}

.form-dark .form-control::placeholder {
  color: rgba(255, 255, 255, .8);
}

.form-dark .form-control:focus {
  border: var(--x-border-width) solid #1b2335;
  background-color: #1b2335;
}

.form-dark .form-control:focus::placeholder {
  color: rgba(255, 255, 255, .6);
}

.form-dark .input-group .input-group-text {
  color: rgba(255, 255, 255, .9);
  background-color: #272e3f;
  border-color: #272e3f;
}

.form-dark .input-group:focus-within .input-group-text {
  background-color: #1b2335;
  border-color: #1b2335;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--x-primary-rgb), var(--x-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(var(--x-secondary-rgb), var(--x-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(var(--x-success-rgb), var(--x-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--x-info-rgb), var(--x-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--x-warning-rgb), var(--x-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--x-danger-rgb), var(--x-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--x-light-rgb), var(--x-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--x-dark-rgb), var(--x-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--x-primary-rgb), var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-primary-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--x-primary-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-primary:hover, .link-primary:focus {
  color: RGBA(110, 70, 204, var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(110, 70, 204, var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(110, 70, 204, var(--x-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--x-secondary-rgb), var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-secondary-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--x-secondary-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: RGBA(255, 163, 51, var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(255, 163, 51, var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 163, 51, var(--x-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--x-success-rgb), var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-success-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--x-success-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-success:hover, .link-success:focus {
  color: RGBA(51, 214, 160, var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(51, 214, 160, var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(51, 214, 160, var(--x-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--x-info-rgb), var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-info-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--x-info-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-info:hover, .link-info:focus {
  color: RGBA(51, 221, 255, var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(51, 221, 255, var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(51, 221, 255, var(--x-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--x-warning-rgb), var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-warning-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--x-warning-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 163, 51, var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(255, 163, 51, var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 163, 51, var(--x-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--x-danger-rgb), var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-danger-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--x-danger-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-danger:hover, .link-danger:focus {
  color: RGBA(204, 41, 82, var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(204, 41, 82, var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(204, 41, 82, var(--x-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--x-light-rgb), var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-light-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--x-light-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-light:hover, .link-light:focus {
  color: RGBA(247, 249, 252, var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(247, 249, 252, var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(247, 249, 252, var(--x-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--x-dark-rgb), var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-dark-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--x-dark-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-dark:hover, .link-dark:focus {
  color: RGBA(12, 18, 34, var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(12, 18, 34, var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(12, 18, 34, var(--x-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--x-emphasis-color-rgb), var(--x-link-opacity, 1)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-emphasis-color-rgb), var(--x-link-underline-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--x-emphasis-color-rgb), var(--x-link-underline-opacity, 1)) !important;
}

.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--x-emphasis-color-rgb), var(--x-link-opacity, .75)) !important;
  -webkit-text-decoration-color: RGBA(var(--x-emphasis-color-rgb), var(--x-link-underline-opacity, .75)) !important;
  text-decoration-color: RGBA(var(--x-emphasis-color-rgb), var(--x-link-underline-opacity, .75)) !important;
}

.focus-ring:focus {
  box-shadow: var(--x-focus-ring-x, 0) var(--x-focus-ring-y, 0) var(--x-focus-ring-blur, 0) var(--x-focus-ring-width) var(--x-focus-ring-color);
  outline: 0;
}

.icon-link {
  -webkit-text-decoration-color: rgba(var(--x-link-color-rgb), var(--x-link-opacity, .5));
  text-decoration-color: rgba(var(--x-link-color-rgb), var(--x-link-opacity, .5));
  text-underline-offset: .25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  align-items: center;
  gap: .375rem;
  display: inline-flex;
}

.icon-link > .bi {
  fill: currentColor;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  transition: transform .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--x-icon-link-transform, translate3d(.25em, 0, 0));
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--x-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --x-aspect-ratio: 100%;
}

.ratio-4x3 {
  --x-aspect-ratio: 75%;
}

.ratio-16x9 {
  --x-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --x-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.visually-hidden:not(caption), .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: var(--x-border-width);
  opacity: .1;
  background-color: currentColor;
  align-self: stretch;
  min-height: 1em;
  display: inline-block;
}

.article, .prose {
  --x-article-font-size: 1rem;
  --x-article-color: var(--x-body-color);
  --x-article-line-height: 1.9;
  --x-article-img-border-radius: var(--x-border-radius);
  --x-article-pre-border-radius: var(--x-border-radius);
  font-size: var(--x-article-font-size);
  color: var(--x-article-color);
  line-height: var(--x-article-line-height);
}

.article > p, .prose > p {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.article > h2, .article > .h2, .prose > h2, .prose > .h2 {
  letter-spacing: -.025em;
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.article > h2:first-child, .article > .h2:first-child, .prose > h2:first-child, .prose > .h2:first-child {
  margin-top: 1em;
}

.article > h3, .article > .h3, .prose > h3, .prose > .h3 {
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
}

.article > h4, .article > .h4, .prose > h4, .prose > .h4 {
  margin-top: 2em;
  font-size: 1em;
  font-weight: 500;
}

.article > img, .prose > img {
  border-radius: var(--x-article-img-border-radius);
}

.article > figure, .article > img, .article > video, .prose > figure, .prose > img, .prose > video {
  margin-top: 2em;
  margin-bottom: 2em;
}

.article pre, .prose pre {
  color: #fff;
  border-radius: var(--x-article-pre-border-radius);
  background-color: #0f172a;
  margin-top: 1.75em;
  margin-bottom: 1.75em;
  padding: 1rem;
  font-size: .875em;
  line-height: 1.7;
  overflow-x: auto;
}

.article > :first-child, .prose > :first-child, .article h2 + *, .article .h2 + *, .article h3 + *, .article .h3 + *, .article hr + *, .prose h2 + *, .prose .h2 + *, .prose h3 + *, .prose .h3 + *, .prose hr + * {
  margin-top: 0;
}

.article > hr, .prose > hr {
  margin-top: 3em;
  margin-bottom: 3em;
}

.article > ul, .article > ol, .prose > ul, .prose > ol {
  padding-left: 1.5rem;
}

.article > ul li, .article > ol li, .prose > ul li, .prose > ol li {
  padding: .25rem 0;
}

.article > blockquote, .prose > blockquote {
  color: #0f172a;
  letter-spacing: -.012em;
  background-color: var(--x-surface-secondary);
  border-radius: .5rem;
  margin-top: 2em;
  margin-bottom: 2em;
  padding: 1.5rem 3.5rem;
  font-size: 1.25rem;
  font-weight: 500;
}

.article > blockquote > p, .prose > blockquote > p {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (max-width: 991.98px) {
  .section-step-lg {
    padding-bottom: 3rem;
  }

  .section-step-lg + .section-step-lg {
    padding-top: 3rem;
  }

  .section-step-lg:last-child {
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .section-step-lg {
    padding-bottom: 5rem;
    position: relative;
  }

  .section-step-lg + .section-step-lg {
    padding-top: 5rem;
  }

  .section-step-lg:last-child {
    padding-bottom: 0;
  }

  .section-step-lg:not(:last-child):before {
    content: "";
    background: url("data:image/svg+xml;charset=utf8,%3Csvg width='355px' height='103px' viewBox='0 0 355 103' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-dasharray='6,12' stroke-linecap='round'%3E%3Cpath stroke='%23e2e8f0' stroke-width='3' transform='translate(173.245679, 51.548257) scale(-1, 1) translate(-173.245679, -51.548257)' d='M-6.75432109,1.54825684 C113.245679,110.326533 233.245679,130.359459 353.245679,61.647035'/%3E%3C/g%3E%3C/svg%3E") center / 360px 100px no-repeat;
    width: 360px;
    height: 100px;
    margin: auto;
    display: block;
    position: absolute;
    bottom: -80px;
    left: 0;
    right: 0;
    transform: rotate(40deg);
  }
}

@media (min-width: 992px) and (max-width: 767.98px) {
  .section-step-lg:not(:last-child):before {
    bottom: 20px;
    transform: rotate(90deg)scale(.7);
  }
}

@media (min-width: 992px) {
  .section-step-lg:nth-child(odd) .row > div:first-child {
    order: 2;
  }

  .section-step-lg:nth-child(odd) .row > div:last-child {
    order: 1;
  }

  .section-step-lg:nth-child(2n):before {
    background: url("data:image/svg+xml;charset=utf8,%3Csvg width='355px' height='103px' viewBox='0 0 355 103' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-dasharray='6,12' stroke-linecap='round'%3E%3Cpath stroke='%23e2e8f0' stroke-width='3' d='M2.24567891,1.54825684 C122.245679,110.326533 242.245679,130.359459 362.245679,61.647035'/%3E%3C/g%3E%3C/svg%3E") center / 360px 100px no-repeat;
    transform: rotate(-40deg);
  }
}

@media (min-width: 992px) and (max-width: 767.98px) {
  .section-step-lg:nth-child(2n):before {
    bottom: 20px;
    transform: rotate(-90deg)scale(.7);
  }
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  color: #3f4254;
  font-weight: 500;
  background: #fff !important;
  border-bottom: 1px solid #f5f8fa !important;
}

.apexcharts-tooltip .apexcharts-tooltip-title {
  padding: .5rem 1rem;
}

.apexcharts-xaxistooltip.apexcharts-theme-light {
  color: #3f4254;
  background: #fff !important;
  border: 0 !important;
  border-radius: .475rem !important;
  box-shadow: 0 0 50px rgba(82, 63, 105, .15) !important;
}

.apexcharts-xaxistooltip.apexcharts-theme-light:before {
  border-bottom: 0 !important;
}

.apexcharts-xaxistooltip.apexcharts-theme-light:after {
  border-bottom-color: #fff !important;
}

.card-rounded-bottom .apexcharts-canvas svg {
  border-bottom-right-radius: .475rem;
  border-bottom-left-radius: .475rem;
}

.rounded .apexcharts-canvas svg {
  border-radius: .475rem !important;
}

.rounded-sm .apexcharts-canvas svg {
  border-radius: .275rem !important;
}

.rounded-lg .apexcharts-canvas svg {
  border-radius: .775rem !important;
}

.rounded-xl .apexcharts-canvas svg {
  border-radius: 1rem !important;
}

.apexcharts-bar-hover-white .apexcharts-bar-area:hover {
  fill: #fff !important;
}

.flatpickr-calendar {
  opacity: 0;
  text-align: center;
  visibility: hidden;
  direction: ltr;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  border: 0;
  border-radius: 5px;
  width: 307.875px;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  animation: none;
  display: none;
  position: absolute;
  box-shadow: 1px 0 #e6e6e6, -1px 0 #e6e6e6, 0 1px #e6e6e6, 0 -1px #e6e6e6, 0 3px 13px rgba(0, 0, 0, .08);
}

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  visibility: visible;
  max-height: 640px;
}

.flatpickr-calendar.open {
  z-index: 99999;
  display: inline-block;
}

.flatpickr-calendar.animate.open {
  animation: .3s cubic-bezier(.23, 1, .32, 1) fpFadeInDown;
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border-top: 1px solid #e6e6e6;
  height: 40px;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before, .flatpickr-calendar:after {
  pointer-events: none;
  content: "";
  border: solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  left: 22px;
}

.flatpickr-calendar.rightMost:before, .flatpickr-calendar.arrowRight:before, .flatpickr-calendar.rightMost:after, .flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before, .flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  display: inline-block;
  position: relative;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  color: rgba(0, 0, 0, .9);
  fill: rgba(0, 0, 0, .9);
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background: none;
  flex: 1;
  height: 34px;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  z-index: 3;
  color: rgba(0, 0, 0, .9);
  fill: rgba(0, 0, 0, .9);
  height: 34px;
  padding: 10px;
  text-decoration: none;
  position: absolute;
  top: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled, .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  left: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 0;
}

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  fill: inherit;
  transition: fill .1s;
}

.numInputWrapper {
  height: auto;
  position: relative;
}

.numInputWrapper input, .numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numInputWrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numInputWrapper span {
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid rgba(57, 57, 57, .15);
  width: 14px;
  height: 50%;
  padding: 0 4px 0 2px;
  line-height: 50%;
  position: absolute;
  right: 0;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, .1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, .2);
}

.numInputWrapper span:after {
  content: "";
  display: block;
  position: absolute;
}

.numInputWrapper span.arrowUp {
  border-bottom: 0;
  top: 0;
}

.numInputWrapper span.arrowUp:after {
  border-bottom: 4px solid rgba(57, 57, 57, .6);
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-top: 4px solid rgba(57, 57, 57, .6);
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, .5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, .05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  color: inherit;
  text-align: center;
  width: 75%;
  height: 34px;
  padding: 7.48px 0 0;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
  position: absolute;
  left: 12.5%;
  transform: translate3d(0, 0, 0);
}

.flatpickr-current-month span.cur-month {
  color: inherit;
  margin-left: .5ch;
  padding: 0;
  font-family: inherit;
  font-weight: 700;
  display: inline-block;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, .05);
}

.flatpickr-current-month .numInputWrapper {
  width: 7ch�;
  width: 6ch;
  display: inline-block;
}

.flatpickr-current-month input.cur-year {
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  height: auto;
  margin: 0;
  padding: 0 0 0 .5ch;
  display: inline-block;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  color: rgba(0, 0, 0, .5);
  pointer-events: none;
  background: none;
  font-size: 100%;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  vertical-align: initial;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  width: auto;
  height: auto;
  margin: -1px 0 0;
  padding: 0 0 0 .5ch;
  position: relative;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, .05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: rgba(0, 0, 0, 0);
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  text-align: center;
  background: none;
  align-items: center;
  width: 100%;
  height: 28px;
  display: flex;
  overflow: hidden;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  flex: 1;
  display: flex;
}

span.flatpickr-weekday {
  cursor: default;
  color: rgba(0, 0, 0, .54);
  text-align: center;
  background: none;
  flex: 1;
  margin: 0;
  font-size: 90%;
  font-weight: bolder;
  line-height: 1;
  display: block;
}

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0;
}

.flatpickr-days {
  align-items: flex-start;
  width: 307.875px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  text-align: left;
  box-sizing: border-box;
  opacity: 1;
  outline: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  padding: 0;
  display: flex;
  transform: translate3d(0, 0, 0);
}

.dayContainer + .dayContainer {
  box-shadow: -1px 0 #e6e6e6;
}

.flatpickr-day {
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  text-align: center;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 150px;
  flex-basis: 14.2857%;
  justify-content: center;
  width: 14.2857%;
  max-width: 39px;
  height: 39px;
  margin: 0;
  font-weight: 400;
  line-height: 39px;
  display: inline-block;
  position: relative;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  background: #e6e6e6;
  border-color: #e6e6e6;
  outline: 0;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  color: #fff;
  background: #959ea9;
  border-color: #959ea9;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  background: #569ff7;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, .3);
  cursor: default;
  background: none;
  border-color: rgba(0, 0, 0, 0);
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, .1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 #569ff7, 5px 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  color: rgba(57, 57, 57, .3);
  cursor: default;
  background: none;
  border: none;
  width: 100%;
  max-width: none;
  display: block;
}

.flatpickr-innerContainer {
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
}

.flatpickr-rContainer {
  box-sizing: border-box;
  padding: 0;
  display: inline-block;
}

.flatpickr-time {
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  height: 0;
  max-height: 40px;
  line-height: 40px;
  display: flex;
  overflow: hidden;
}

.flatpickr-time:after {
  content: "";
  clear: both;
  display: table;
}

.flatpickr-time .numInputWrapper {
  float: left;
  flex: 1;
  width: 40%;
  height: 40px;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: inherit;
  line-height: inherit;
  color: #393939;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  position: relative;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  border: 0;
  outline: 0;
}

.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  align-self: center;
  width: 2%;
  font-weight: bold;
}

.flatpickr-time .flatpickr-am-pm {
  cursor: pointer;
  text-align: center;
  outline: 0;
  width: 18%;
  font-weight: 400;
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.flatpickr-calendar {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background: #fff;
  border: 0;
  border-radius: .75rem;
  width: auto;
  margin-top: -2px;
  box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06);
}

.flatpickr-calendar:before, .flatpickr-calendar:after {
  display: none;
}

.flatpickr-months {
  background-color: #fff;
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.flatpickr-months .flatpickr-month {
  height: 60px;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  color: #0f172a;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  padding: 0;
  line-height: 100%;
  transition: background-color .3s linear;
  display: flex;
  top: 13px;
}

@media (prefers-reduced-motion: reduce) {
  .flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
    transition: none;
  }
}

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  fill: rgba(15, 23, 42, .7);
}

.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #0f172a;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #0f172a;
}

.flatpickr-months .flatpickr-prev-month {
  margin-left: 15px;
}

.flatpickr-months .flatpickr-next-month {
  margin-right: 15px;
}

.flatpickr-current-month {
  color: #0f172a;
  padding-top: 18px;
  font-size: 1.125rem;
}

.flatpickr-current-month span.cur-month:hover, .flatpickr-current-month .numInputWrapper:hover {
  background-color: rgba(0, 0, 0, 0);
}

.flatpickr-current-month .numInputWrapper span {
  border: 0;
  padding: 0;
  right: -5px;
}

.flatpickr-current-month .numInputWrapper span:after {
  left: 3px;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(255, 255, 255, .7);
}

.flatpickr-current-month .numInputWrapper span.arrowUp:hover:after {
  border-bottom-color: #fff;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(255, 255, 255, .7);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:hover:after {
  border-top-color: #fff;
}

span.flatpickr-weekday {
  color: #0f172a;
  font-weight: 600;
}

.flatpickr-day {
  color: #0f172a;
  border-radius: var(--x-border-radius);
  border: 0;
  font-size: .875rem;
}

.flatpickr-day.today {
  color: #8957ff !important;
}

.flatpickr-day.today:hover {
  color: #8957ff;
  background-color: rgba(0, 0, 0, 0);
}

.flatpickr-day:hover {
  color: #0f172a;
  background-color: #f1f5f9;
}

.flatpickr-day.selected {
  background-color: #8957ff;
  color: #fff !important;
}

.flatpickr-day.selected:hover {
  color: #fff;
  background-color: #8957ff;
}

.numInputWrapper span:hover {
  background-color: rgba(0, 0, 0, 0);
}

.flatpickr-time {
  border-top: 1px solid #eef2f6;
}

.flatpickr-innerContainer {
  padding: 15px;
}

.scrollbar {
  scrollbar-color: #cbd5e1 transparent;
  scrollbar-width: thin;
}

.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  width: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none;
  background-color: #cbd5e1;
  border-radius: 10px;
}

.scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: #c1cad6;
}

.snippet-preview + pre {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 0;
}

/*# sourceMappingURL=main.css.map */
